import { useGetCycleById } from 'features/cycles/hooks';
import { useUser } from 'features/auth/hooks';
import {
  useParams,
  useSearchParams,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';

export const useRouteParams = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: user } = useUser();
  const { data: cycle } = useGetCycleById();
  // Redux
  const actions_counter = useSelector((state) => state.global.actions_counter);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  // Convert searchParams to a regular object for easier use
  const queryParams = {};
  for (const [key, value] of searchParams.entries()) {
    queryParams[key] = value;
  }

  // Determine creating and editing states from URL
  const creating = location.pathname === '/cycles/new';
  const show_privacy_policy =
    location.pathname === '/privacy-policy' || queryParams.privacy_policy;
  const show_about_modal = location.pathname === '/about' || queryParams.about;

  const user_owns_cycle = useMemo(() => {
    if (!user || !cycle) return false;
    return is_authenticated && cycle.user_id === user.id;
  }, [is_authenticated, cycle, user]);

  const public_cycle_edited = actions_counter > 0;

  // Set multiple query parameters at once or a single parameter
  const setQueryParams = (keyOrObject, value) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);

      // Handle both object and key-value formats
      if (typeof keyOrObject === 'object' && keyOrObject !== null) {
        // Handle object format: setQueryParams({ key1: 'value1', key2: 'value2' })
        Object.entries(keyOrObject).forEach(([k, v]) => {
          if (v === null || v === undefined || v === '') {
            newParams.delete(k);
          } else {
            newParams.set(k, v);
          }
        });
      } else {
        // Handle key-value format: setQueryParams('key', 'value')
        const key = keyOrObject;
        if (value === null || value === undefined || value === '') {
          newParams.delete(key);
        } else {
          newParams.set(key, value);
        }
      }

      return newParams;
    });
  };

  // Delete one or more parameters
  const deleteQueryParams = (keyOrKeys) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);

      if (Array.isArray(keyOrKeys)) {
        // Handle array format: deleteQueryParams(['key1', 'key2'])
        keyOrKeys.forEach((key) => newParams.delete(key));
      } else {
        // Handle single key format: deleteQueryParams('key')
        newParams.delete(keyOrKeys);
      }

      return newParams;
    });
  };

  // Navigate while preserving current search params
  const navigateWithParams = (path) => {
    navigate({
      pathname: path,
      search: searchParams.toString(),
    });
  };

  const closeAboutModal = () => {
    if (location.pathname === '/about') {
      navigate(location?.state?.from || '/');
    } else {
      deleteQueryParams('about');
    }
  };

  // Navigate and update specific params
  const navigateWithNewParams = (path, paramObject) => {
    const newParams = new URLSearchParams(searchParams);

    Object.entries(paramObject).forEach(([key, value]) => {
      if (value === null || value === undefined || value === '') {
        newParams.delete(key);
      } else {
        newParams.set(key, value);
      }
    });

    navigate({
      pathname: path,
      search: newParams.toString(),
    });
  };

  const closePrivacyPolicy = () => {
    if (location.pathname === '/privacy-policy') {
      navigate(location?.state?.from || '/');
    } else {
      deleteQueryParams('privacy_policy');
    }
  };

  return {
    // URL path parameters
    params,
    // URL query parameters as searchParams object
    searchParams,
    // URL query parameters as a plain object
    queryParams,
    // Current location
    location,
    creating,
    show_privacy_policy,
    show_about_modal,
    user_owns_cycle,
    public_cycle_edited,
    // Basic search params methods
    setSearchParams,
    // Enhanced methods with flexible parameters
    setQueryParams,
    deleteQueryParams,
    navigateWithParams,
    navigateWithNewParams,
    closePrivacyPolicy,
    closeAboutModal,
    // Convenience aliases for backward compatibility
    setQueryParam: (key, value) => setQueryParams(key, value),
    deleteQueryParam: (key) => deleteQueryParams(key),
  };
};
