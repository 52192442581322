import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { slugify } from 'helpers/slugify';

const initialState = {
  menu_selected: 'compounds',
  menu_items: [
    'compounds',
    'ancillaries',
    'peptides+',
    'blood work',
    'journal',
  ],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCycleMenu: (state, { payload }) => {
      state.menu_selected = payload;
    },
  },
});

export const selectDashboardInfo = createSelector(
  (state) => state.dashboard.menu_selected,
  (state) => state.dashboard.menu_items,
  (menu_selected, menu_items) => {
    const menu_slugs_set = new Set(menu_items.map((item) => slugify(item)));
    return {
      menu_selected,
      menu_items,
      menu_slugs_set,
    };
  }
);

export const { setCycleMenu } = dashboardSlice.actions;

export default dashboardSlice.reducer;
