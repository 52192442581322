// MUI
import {
  Button,
  TextField,
  FormControl,
  Typography,
  IconButton,
  Chip,
  Box,
  Select,
  Grid,
  Menu,
  MenuItem,
  InputLabel,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Checkbox,
  useMediaQuery,
  useTheme,
  Badge,
} from '@mui/material';
import {
  Edit,
  Check,
  Close,
  Delete,
  MoreVert,
  Notes,
  Public,
  PublicOff,
  SaveAlt,
  IosShare,
  Settings,
  VerifiedUser,
  Lock,
} from '@mui/icons-material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// React
import { shallowEqual, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Features
import {
  useCreateCycleMutation,
  useSaveCycleMutation,
  useDeleteCycleMutation,
} from 'features/cycles/cyclesAPI';
import { useGetCycleById, useUpdateCycle } from 'features/cycles/hooks';
import { useGetDosesByCycleId } from 'features/doses/hooks';
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
import { useGetPeptidesByCycleId } from 'features/peptides/hooks';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
// Hooks
import { useAuth } from 'hooks/useAuth';
import { useRouteParams } from 'hooks/useRouteParams';
import { useUser } from 'features/auth/hooks';
// Other
import { CycleMenu, ShareDialog } from 'components';
import moment from 'moment';
import { customToast } from 'helpers/customToast';

const initial_form_state = {
  title: '',
  start_date: moment(),
  weeks: '',
  notes: '',
};

const [xs, sm] = [12, 4];

const CycleInfo = () => {
  // Navigation
  const navigate = useNavigate();
  const { creating, user_owns_cycle, public_cycle_edited } = useRouteParams();
  const { cycle_id: active_cycle_id } = useParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const { data: doses, isLoading: doses_loading } = useGetDosesByCycleId();
  const { data: ancillaries, isLoading: ancillaries_loading } =
    useGetAncillariesByCycleId();
  const { data: peptides, isLoading: peptides_loading } =
    useGetPeptidesByCycleId();
  const { data: bloods, isLoading: bloods_loading } = useGetBloodsByCycleId();

  // Auth
  const { openSignUp } = useAuth();
  // React Query
  const { data: user, isLoading: user_loading } = useUser();
  const { id: user_id } = user || {};
  const {
    data: cycle,
    isSuccess,
    isLoading: cycle_loading,
  } = useGetCycleById();
  const { title, start_date, weeks, notes, is_public } = isSuccess
    ? cycle
    : { ...initial_form_state, id: '' };
  const [createCycle] = useCreateCycleMutation();
  const [saveCycle] = useSaveCycleMutation();
  const [updateCycle] = useUpdateCycle();
  const [deleteCycle] = useDeleteCycleMutation();

  // Component State
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [notes_showing, setNotesShowing] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editing_visibility_dialog_open, setEditingVisibilityDialogOpen] =
    useState(false);
  const [delete_cycle_dialog_open, setDeleteCycleDialogOpen] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  // Hooks
  const theme = useTheme();
  const is_small_screen = useMediaQuery((theme) =>
    theme.breakpoints.down('sm')
  );
  const is_medium_screen = useMediaQuery((theme) =>
    theme.breakpoints.down('md')
  );
  const [form_data, setFormData] = useState(
    title ? { title, start_date, weeks, notes } : initial_form_state
  );

  useEffect(() => {
    setEditing(false);
  }, [active_cycle_id]);

  useEffect(() => {
    if (creating) {
      setFormData(initial_form_state);
    } else {
      setFormData({ title, start_date, weeks, notes });
    }
  }, [editing]);

  const handleFormDataChange = (e) => {
    if (!user && e.target.name === 'weeks' && e.target.value > 16) {
      openSignUp('greater_than_16_weeks');
      customToast(
        'info',
        'Sign Up or Login to Create a Cycle longer than 16 weeks.',
        5000
      );
      return;
    }
    setFormData({ ...form_data, [e.target.name]: e.target.value });
  };

  const handleStartDateChange = (date) => {
    if (!user) {
      openSignUp('change_start_date');
      customToast(
        'info',
        'Sign Up or Login to Create a Cycle and change the date.',
        5000
      );
      return;
    }
    setFormData({ ...form_data, start_date: date });
  };

  const handleShare = () => {
    setShareDialogOpen(true);
    setAnchorElNav(null);
  };

  const onChangeVisibility = async () => {
    setEditingVisibilityDialogOpen(false);
    setAnchorElNav(null);
    if (!user) {
      openSignUp('change_visibility');
      customToast('info', 'Sign Up or Login to save and delete Cycles.', 5000);
      return;
    }

    // Update the cycle with the new visibility
    const result = await updateCycle({
      ...form_data,
      id: active_cycle_id,
      updated_at: new Date().toISOString(),
      is_public: !is_public,
    }).unwrap();
    if (result?.length > 0 && result[0]?.is_public) {
      customToast('info', 'Cycle is now PUBLIC', 4000);
    } else {
      customToast('info', 'Cycle is now PRIVATE', 4000);
    }
  };

  const onDeleteCycle = async () => {
    setDeleteCycleDialogOpen(false);
    setAnchorElNav(null);
    if (!user) {
      openSignUp('delete_cycle');
      customToast('info', 'Sign Up or Login to save and delete Cycles.', 5000);
      return;
    }

    const result = await deleteCycle(active_cycle_id).unwrap();
    // Navigate to home after successful deletion
    navigate('/');
  };

  const onSaveCycle = async () => {
    if (!is_authenticated) {
      openSignUp('save_cycle');
      customToast('info', 'Sign Up or Login to save this Cycle.', 5000);
      return;
    }

    if (doses?.length === 0) {
      customToast('info', 'Cycle empty. Add compounds before saving.');
      return;
    }

    try {
      // Need to pass the mutated doses if the user has made changes
      // otherwise, we just need to pass the cycle_data for a direct copy via RPC
      const { data: new_cycle } = await saveCycle({
        cycle_data: { ...form_data, is_public: false, user_id },
        doses_data: doses,
        ancillaries_data: ancillaries,
        peptides_data: peptides,
        bloods_data: bloods,
      }).unwrap();
      navigate(`/cycles/${new_cycle.id}`);
    } catch (error) {
      // let msg = JSON.parse(error?.message);
      console.error(error);
      customToast('error', 'Something went wrong. Try again.');
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Creating a New Cycle
    if (
      creating &&
      form_data.title &&
      form_data.start_date &&
      form_data.weeks
    ) {
      // Extract the cycle_id from the response and navigate to the new cycle
      const result = await createCycle({
        ...form_data,
        user_id,
      }).unwrap();
      const new_cycle = result[0];
      navigate(`/cycles/${new_cycle?.id || ''}`);
    }
    // Editing an Existing Cycle
    else if (form_data.title && form_data.start_date && form_data.weeks) {
      if (shallowEqual(form_data, { title, start_date, weeks, notes })) {
        customToast('info', 'No changes');
        return;
      }
      await updateCycle({
        ...form_data,
        updated_at: new Date().toISOString(),
        id: active_cycle_id,
        is_public,
        user_id,
      }).unwrap();
      setEditing(false);
    }
    // Missing Fields - Do not attempt any mutation/creation and alert user.
    else {
      customToast('error', 'Make sure to fill all the fields');
    }
  };

  if (user_loading || cycle_loading) {
    return <></>;
  }

  if (creating || editing) {
    return (
      <form onSubmit={onSubmit}>
        <Grid
          container
          spacing={1}
          direction='row'
          alignItems={'baseline'}
          justifyContent={is_medium_screen ? 'stretch' : 'center'}
          p={1}>
          <Grid item xs={xs} md alignSelf={'center'}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                required
                size='small'
                name='title'
                value={form_data.title}
                id='standard-basic'
                variant='standard'
                placeholder='Name this Cycle'
                onChange={handleFormDataChange}
                sx={{ mr: 1, flex: 1 }}
                inputProps={{ style: { fontSize: '1.3rem' } }}
                disabled={false}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm md='auto'>
            <Box
              sx={{
                display: 'flex',
                flex: 1,
                justifyContent: is_small_screen ? 'center' : '',
              }}>
              <FormControl sx={{ flex: 1, minWidth: 150, m: 1, ml: 0 }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    required
                    name='start_date'
                    value={form_data.start_date}
                    label='Start Date'
                    inputFormat='MM/DD/YYYY'
                    onChange={(value) => handleStartDateChange(value)}
                    renderInput={(params) => (
                      <TextField size='small' {...params} />
                    )}
                  />
                </LocalizationProvider>
              </FormControl>
              <FormControl sx={{ minWidth: 90, m: 1 }} size='small'>
                <InputLabel id='weeks-input-label'>Weeks</InputLabel>
                <Select
                  autoWidth
                  required
                  labelId='weeks-input-label'
                  name='weeks'
                  value={form_data.weeks}
                  label='Weeks'
                  onChange={handleFormDataChange}>
                  {Array.from({ length: 100 }, (_, i) => i + 1).map((i) => (
                    <MenuItem key={i} value={i}>
                      {i}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs sm='auto' alignSelf={'center'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: is_small_screen ? 'center' : 'flex-end',
                alignItems: 'flex-end',
              }}>
              <IconButton
                variant='contained'
                onClick={() => {
                  setEditing(false);
                  creating
                    ? setFormData(initial_form_state)
                    : setFormData({ title, start_date, weeks });
                }}>
                <Close />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs sm='auto' alignSelf={'center'}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: is_small_screen ? 'center' : 'flex-end',
                alignItems: 'flex-end',
              }}>
              <Button variant={false ? 'outlined' : 'contained'} type='submit'>
                <Check />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }}>
              <TextField
                name='notes'
                size='small'
                placeholder='Add notes (optional)'
                multiline
                minRows={1}
                maxRows={8}
                value={form_data.notes}
                onChange={handleFormDataChange}
                sx={{ width: '100%' }}
                disabled={user ? false : true}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
    );
  }

  return (
    <Box
      alignItems={'center'}
      justifyContent={'center'}
      p={1}
      sx={{ width: '100%', display: 'flex', maxWidth: '100%' }}>
      <Grid
        container
        direction='row'
        alignItems={'center'}
        justifyContent={'center'}
        p={1}
        pb={0}
        sx={{ width: '100%', rowGap: 1 }}>
        {/* Grid #1 - Start */}
        <Grid
          item
          xs={12}
          sm='auto'
          ml={1}
          container
          direction='row'
          justifyContent={() => {
            if (is_small_screen) return 'center';
            if (is_medium_screen) return 'flex-end';
            return 'center';
          }}
          alignItems='center'
          wrap={is_small_screen ? 'wrap' : 'nowrap'}
          sx={{ rowGap: 1 }}>
          <Grid item xs={12} sm>
            <Box
              mx={1}
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90vw',
                whiteSpace: 'nowrap',
                // textAlign: is_small_screen ? 'center' : 'right',
              }}>
              <Typography
                variant='h5'
                align={is_small_screen ? 'center' : 'right'}>
                {title}
              </Typography>
            </Box>
          </Grid>
          {is_public && (
            <Grid item xs='auto' sm='auto' mr={1}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Tooltip
                  title={
                    user_owns_cycle
                      ? 'You Own this Cycle'
                      : 'This Cycle is Locked'
                  }>
                  {user_owns_cycle ? <VerifiedUser /> : <Lock />}
                </Tooltip>
              </Box>
            </Grid>
          )}
          <Grid item xs='auto' sm='auto'>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Chip
                sx={{ m: 0.5 }}
                label={`${moment(start_date).format('LL')}`}
              />
              <Chip sx={{ m: 0.5 }} label={`${weeks} Weeks`} />
            </Box>
          </Grid>
        </Grid>
        {/* Grid #1 - End */}
        {/* Grid #2 - Start */}
        <Grid
          item
          xs={12}
          sm='auto'
          mx={1}
          container
          direction='row'
          justifyContent={is_small_screen ? 'space-evenly' : 'flex-start'}
          alignItems='center'
          wrap='nowrap'
          sx={{ columnGap: 1.2 }}>
          {is_public && (
            <Grid item xs='auto' sm='auto'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: is_small_screen ? 'flex-end' : 'center',
                }}>
                <Tooltip title='Share this Cycle'>
                  <IconButton variant='contained' onClick={handleShare}>
                    <IosShare />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          )}
          {active_cycle_id !== 'no_auth_cycle' && (
            <Grid item xs='auto' sm='auto'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: is_small_screen ? 'flex-end' : 'center',
                }}>
                <Tooltip
                  title={
                    user_owns_cycle
                      ? `Your Cycle is ${is_public ? 'Public' : 'Private'}`
                      : `${is_public ? 'Public' : 'Private'} Cycle`
                  }
                  disableInteractive>
                  <span>
                    <IconButton
                      variant='contained'
                      onClick={(e) => {
                        user_owns_cycle && setEditingVisibilityDialogOpen(true);
                      }}
                      sx={{
                        background: is_public
                          ? theme.palette.secondary.main
                          : '',
                        '&:hover': {
                          background: is_public
                            ? theme.palette.secondary.light
                            : '',
                        },
                        pointerEvents: user_owns_cycle ? 'auto' : 'none',
                      }}>
                      {is_public ? (
                        <Public
                          sx={{
                            color: is_public ? theme.palette.common.white : '',
                          }}
                        />
                      ) : (
                        <PublicOff
                          sx={{
                            color: is_public ? theme.palette.common.white : '',
                          }}
                        />
                      )}
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          )}
          {true && (
            <Grid item xs='auto' sm='auto'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: is_small_screen ? 'flex-end' : 'center',
                }}>
                <Tooltip title='Edit Cycle'>
                  <IconButton
                    variant='contained'
                    onClick={() => setEditing(!editing)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          )}
          {notes && (
            <Grid item xs='auto' sm='auto'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: is_small_screen ? 'flex-end' : 'center',
                }}>
                <IconButton
                  variant='contained'
                  onClick={() => setNotesShowing(!notes_showing)}>
                  <Notes />
                </IconButton>
              </Box>
            </Grid>
          )}
          {(!user_owns_cycle || !is_authenticated) && (
            <Grid item xs='auto' sm='auto'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: is_small_screen ? 'flex-end' : 'center',
                }}>
                <Tooltip
                  title={
                    public_cycle_edited ? 'Save Edited Cycle' : 'Save Cycle'
                  }>
                  <Badge
                    variant='dot'
                    invisible={!public_cycle_edited}
                    overlap='circular'
                    color='secondary'>
                    <IconButton variant='contained' onClick={onSaveCycle}>
                      <SaveAlt />
                    </IconButton>
                  </Badge>
                </Tooltip>
              </Box>
            </Grid>
          )}
          {active_cycle_id !== 'no_auth_cycle' && user_owns_cycle && (
            <Grid item xs='auto' sm='auto'>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: is_small_screen ? 'flex-end' : 'center',
                }}>
                <Tooltip title='Settings'>
                  <IconButton
                    variant='contained'
                    onClick={(e) => setAnchorElNav(e.currentTarget)}>
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          )}
        </Grid>
        {/* Grid #2 - End */}
        {/* Grid #3 - Start */}
        <Grid item xs={12} sm={12} md='auto'>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            <CycleMenu is_small_screen={is_small_screen} />
          </Box>
        </Grid>
        {/* Grid #3 - End */}
        {/* Grid #4 - Start */}
        {notes && notes_showing && (
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box
              sx={{
                width: '100%',
                p: 1,
                m: 0,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxWidth: '90vw',
              }}>
              <Typography variant='subtitle1' align={'center'}>
                {notes}
              </Typography>
            </Box>
          </Grid>
        )}
        {/* Grid #4 - End */}
      </Grid>

      <Menu
        anchorEl={anchorElNav || null}
        keepMounted
        open={Boolean(anchorElNav)}
        onClose={() => setAnchorElNav(null)}>
        {is_public && (
          <MenuItem
            onClick={handleShare}
            sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ListItemIcon>
              <IosShare fontSize='small' />
            </ListItemIcon>
            <ListItemText>Share</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setEditingVisibilityDialogOpen(true);
          }}
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListItemIcon>
            <Public
              fontSize='small'
              sx={{
                color: is_public ? theme.palette.secondary.main : '',
              }}
            />
          </ListItemIcon>
          <ListItemText>Change Visibility</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            setDeleteCycleDialogOpen(true);
          }}
          sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <ListItemIcon>
            <Delete fontSize='small' />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <Dialog
        open={editing_visibility_dialog_open}
        onClose={() => {
          setAnchorElNav(null);
          setEditingVisibilityDialogOpen(false);
        }}
        closeAfterTransition={false}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>
          {is_public ? 'Make Cycle Private' : 'Make Cycle Public'}
        </DialogTitle>
        <DialogContent>
          {!is_public
            ? "Are you sure? This will make your cycle and it's contents visible on the public internet."
            : "Are you sure? This will make your cycle and it's contents private."}
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => {
              setEditingVisibilityDialogOpen(false);
              setAnchorElNav(null);
            }}>
            Cancel
          </Button>
          <Button color='info' variant='contained' onClick={onChangeVisibility}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={delete_cycle_dialog_open}
        onClose={() => {
          setAnchorElNav(null);
          setDeleteCycleDialogOpen(false);
        }}
        closeAfterTransition={false}
        maxWidth='sm'
        fullWidth>
        <DialogTitle>Delete Cycle</DialogTitle>
        <DialogContent>
          Are you sure? This will permanently delete the cycle and all of it's
          contents.
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            onClick={() => {
              setDeleteCycleDialogOpen(false);
              setAnchorElNav(null);
            }}>
            Cancel
          </Button>
          <Button color='error' variant='contained' onClick={onDeleteCycle}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <ShareDialog
        open={shareDialogOpen}
        onClose={() => setShareDialogOpen(false)}
        title={title}
        description={`Check out this cycle: ${title}`}
      />
    </Box>
  );
};

export default CycleInfo;
