// src/middleware/apiListener.js
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { setGlobalAPIError } from 'features/global/globalSlice';
import { api_endpoint_registry } from 'helpers/apiRegistry';
import { customToast } from 'helpers/customToast';

// Rename to apiListenerMiddleware
export const apiListenerMiddleware = createListenerMiddleware();

apiListenerMiddleware.startListening({
  predicate: (action) =>
    !action?.meta?.condition &&
    !api_endpoint_registry['auth'].has(action?.meta?.arg?.endpointName) &&
    !action?.type.endsWith('/pending'),
  effect: (action, listenerApi) => {
    // This listener matches non-auth related fulfilled/rejected RTK actions

    // console.log('API Listener Middleware:', action);
    const { type } = action;

    if (type.endsWith('/rejected')) {
      listenerApi.dispatch(setGlobalAPIError(true));
    }
  },
});

// Specific listener for auth failures
// here's the check that allows us to detect slice-related actions
// --> api_endpoint_registry['auth'].has(action?.meta?.arg?.endpointName)
apiListenerMiddleware.startListening({
  predicate: (action) =>
    false && // <-- Change to true to enable this listener
    !action?.meta?.condition &&
    api_endpoint_registry['auth'].has(action?.meta?.arg?.endpointName),
  effect: (action, listenerApi) => {
    // Add auth-specific logic here
  },
});
