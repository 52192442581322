// src/components/Auth/OwnerProtectedLayout.jsx
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectDashboardInfo } from 'features/dashboard/dashboardSlice';
import { useGetCycleById } from 'features/cycles/hooks';
import { useUser } from 'features/auth/hooks';

function OwnerProtectedLayout() {
  const { data: user, isLoading: user_loading } = useUser();

  const {
    data: cycle,
    isLoading: cycle_loading,
    isError: cycle_error,
  } = useGetCycleById();

  const { cycle_id, menu_selected } = useParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const { menu_slugs_set } = useSelector(selectDashboardInfo);

  if (user_loading || cycle_loading) {
    return <></>;
  }

  // If there's an error loading the cycle, redirect home
  if (cycle_error) {
    // This is where we can determine if the error was unauthorized
    // and if so, we can push to the dataLayer to track the event
    return <Navigate to='/' replace />;
  }

  if (!is_authenticated && !cycle) {
    if (cycle_id === 'no_auth_cycle' && menu_slugs_set.has(menu_selected)) {
      return <Outlet />;
    } else {
      return <Navigate to='/cycles/no_auth_cycle/compounds' replace />;
    }
  } else if (is_authenticated && (!cycle || cycle_id === 'no_auth_cycle')) {
    if (cycle_id === 'no_auth_cycle') {
      return <Navigate to='/' replace />;
    } else {
      return <Navigate to='/cycles/new' replace />;
    }
  } else if (cycle_id && !menu_slugs_set.has(menu_selected)) {
    return <Navigate to={`/cycles/${cycle_id}/compounds`} replace />;
  } else {
    return <Outlet />;
  }
}

export default OwnerProtectedLayout;
