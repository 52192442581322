// React
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
// Internal
import {
  Header,
  Dashboard,
  ProtectedLayout,
  OwnerProtectedLayout,
  NoAuthLayout,
  RootRedirect,
  WaitForAuthLayout,
  DebugRouter,
} from './components';
// MUI
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { theme, dark_theme } from './lib/theme';
// Supabase
import { supabase } from './lib/api';
// Helpers
import { customToast } from './helpers/customToast';
// React Query
import { useUser } from 'features/auth/hooks';
// Google Tag Manager
import initializeGoogleTagManager from 'helpers/GoogleTagManager';
initializeGoogleTagManager();

function App() {
  const { data: user, isLoading, isFetching } = useUser();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        if (event === 'PASSWORD_RECOVERY') {
          const newPassword = prompt(
            'What would you like your new password to be?'
          );
          const { data, error } = await supabase.auth.updateUser({
            password: newPassword,
          });

          if (data) customToast('success', 'Password updated successfully!');
          if (error) customToast('There was an error updating your password.');
        }
      }
    );

    return () => {
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  return (
    // <ThemeProvider theme={user ? theme : dark_theme}>
    <ThemeProvider theme={true ? theme : dark_theme}>
      <CssBaseline />
      <Router>
        <Header />
        <Routes>
          {/* Public routes */}
          <Route path='/' element={<RootRedirect />} />

          {/* Wait for Auth routes - just passes the Outlet after user loads/rejects */}
          <Route element={<WaitForAuthLayout />}>
            <Route path='/privacy-policy' element={<Dashboard />} />
            <Route path='/about' element={<Dashboard />} />
          </Route>

          {/* Auth routes - redirect if already authenticated */}
          <Route element={<NoAuthLayout />}>
            <Route path='/login' element={<Dashboard />} />
            <Route path='/signup' element={<Dashboard />} />
          </Route>

          {/* Authenticated routes */}
          <Route element={<ProtectedLayout />}>
            <Route path='/cycles/new' element={<Dashboard />} />
          </Route>

          {/* Owner Protected routes */}
          <Route element={<OwnerProtectedLayout />}>
            <Route path='/cycles/:cycle_id' element={<Dashboard />} />
            <Route
              path='/cycles/:cycle_id/:menu_selected'
              element={<Dashboard />}
            />
          </Route>

          {/* Fallback */}
          <Route path='*' element={<Navigate to='/' replace />} />
        </Routes>
        <ToastContainer />
      </Router>
    </ThemeProvider>
  );
}

export default App;
