// React
import { useSelector, useDispatch } from 'react-redux';
// RTK Query API
import {
  useGetPeptidesByCycleIdQuery,
  useCreatePeptideMutation,
  useUpdatePeptideMutation,
  useDeletePeptideMutation,
} from 'features/peptides/peptidesAPI';
// Redux
import {
  createPeptide,
  updatePeptide,
  deletePeptide,
} from 'features/peptides/peptidesSlice';
// Helpers
import { v4 as uuid_v4 } from 'uuid';
import { useUser } from 'features/auth/hooks';
import { useParams } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';

// Custom Hook wrapper around useGetPeptidesByCycleIdQuery
export const useGetPeptidesByCycleId = () => {
  const { cycle_id: active_cycle_id } = useParams();
  const { isLoading: user_loading } = useUser();
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);
  const no_auth_peptides_mutated = useSelector(
    (state) => state.peptides.no_auth_peptides_mutated
  );
  const no_auth_peptides = useSelector(
    (state) => state.peptides.no_auth_peptides
  );
  const cycle_exists = useSelector((state) => state.cycles.cycle_exists);
  const use_redux_peptides =
    active_cycle_id === 'no_auth_cycle' ||
    (no_auth_peptides_mutated && active_cycle_id === no_auth_cycle.id);

  const {
    data: peptides,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetPeptidesByCycleIdQuery(active_cycle_id, {
    skip:
      user_loading ||
      active_cycle_id === 'no_auth_cycle' ||
      !active_cycle_id ||
      !cycle_exists ||
      use_redux_peptides,
  });

  return {
    data: use_redux_peptides ? no_auth_peptides : peptides,
    isSuccess: use_redux_peptides ? true : isSuccess,
    isLoading,
    isFetching,
    isError,
  };
};

// Create, Update and Delete hooks following doses pattern
export const useCreatePeptide = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useCreatePeptideMutation();
  const dispatch = useDispatch();

  const createPeptideWrapper = async (peptide_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(peptide_data);
    } else {
      dispatch(
        createPeptide({
          ...peptide_data,
          id: uuid_v4(),
          created_at: new Date().toISOString(),
          date:
            typeof peptide_data.date === 'string'
              ? peptide_data.date
              : peptide_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
        })
      );
      return Promise.resolve({ data: peptide_data });
    }
  };

  return [createPeptideWrapper];
};

export const useUpdatePeptide = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdatePeptideMutation();
  const dispatch = useDispatch();

  const updatePeptideWrapper = (peptide_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(peptide_data);
    } else {
      return {
        unwrap: async () => {
          // If the user is not auth'd, and weeks > 16, throw Error
          if (!is_authenticated && peptide_data?.stop_wk > 16) {
            throw new Error('no_auth_weeks_limit_hit');
          }
          dispatch(
            updatePeptide({
              ...peptide_data,
              date:
                typeof peptide_data.date === 'string'
                  ? peptide_data.date
                  : peptide_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
            })
          );
          return { data: peptide_data };
        },
      };
    }
  };

  return [updatePeptideWrapper];
};

export const useDeletePeptide = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useDeletePeptideMutation();
  const dispatch = useDispatch();

  const deletePeptideWrapper = (peptide_id) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(peptide_id);
    } else {
      return {
        unwrap: async () => {
          dispatch(deletePeptide(peptide_id));
          return { data: peptide_id };
        },
      };
    }
  };

  return [deletePeptideWrapper];
};
