// src/features/auth/hooks.js
import { useSelector } from 'react-redux';
import { useGetUserQuery } from 'features/auth/authAPI';

// Custom Hook wrapper around useGetUserQuery
export const useUser = () => {
  const auth_failed = useSelector((state) => state.auth.auth_failed);

  const {
    data: user,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetUserQuery(undefined, {
    skip: auth_failed,
  });

  return {
    data: user,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  };
};
