// React
import { useSelector } from 'react-redux';
// RTK Query API
import { useGetAncillariesByCycleIdQuery } from 'features/ancillaries/ancillariesAPI';
// Redux
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { v4 as uuid_v4 } from 'uuid';
import { useRouteParams } from 'hooks/useRouteParams';
import { useUser } from 'features/auth/hooks';
import {
  useCreateAncillaryMutation,
  useUpdateAncillaryMutation,
  useDeleteAncillaryMutation,
} from 'features/ancillaries/ancillariesAPI';
import {
  createAncillary,
  deleteAncillary,
  updateAncillary,
} from 'features/ancillaries/ancillariesSlice';

// Custom Hook wrapper around useGetAncillariesByCycleIdQuery
export const useGetAncillariesByCycleId = () => {
  const { cycle_id: active_cycle_id } = useParams();
  const { isLoading: user_loading } = useUser();
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);
  const no_auth_ancillaries = useSelector(
    (state) => state.ancillaries.no_auth_ancillaries
  );
  const no_auth_ancillaries_mutated = useSelector(
    (state) => state.ancillaries.no_auth_ancillaries_mutated
  );
  const cycle_exists = useSelector((state) => state.cycles.cycle_exists);
  const use_redux_ancillaries =
    active_cycle_id === 'no_auth_cycle' ||
    (no_auth_ancillaries_mutated && active_cycle_id === no_auth_cycle.id);

  const {
    data: ancillaries,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetAncillariesByCycleIdQuery(active_cycle_id, {
    skip:
      user_loading ||
      active_cycle_id === 'no_auth_cycle' ||
      !active_cycle_id ||
      !cycle_exists ||
      use_redux_ancillaries,
  });

  return {
    data: use_redux_ancillaries ? no_auth_ancillaries : ancillaries,
    isSuccess: use_redux_ancillaries ? true : isSuccess,
    isLoading,
    isFetching,
    isError,
  };
};

// Custom Hook wrapper around useCreateAncillaryMutation, similar to features/doses/hooks.js
export const useCreateAncillary = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useCreateAncillaryMutation();
  const dispatch = useDispatch();

  const createAncillaryWrapper = async (ancillary_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(ancillary_data);
    } else {
      // Dispatch the create ancillary action
      dispatch(
        createAncillary({
          ...ancillary_data,
          id: uuid_v4(),
          created_at: new Date().toISOString(),
          date:
            typeof ancillary_data.date === 'string'
              ? ancillary_data.date
              : ancillary_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
        })
      );
      return Promise.resolve({ data: ancillary_data });
    }
  };

  return [createAncillaryWrapper];
};

// Custom Hook wrapper around useUpdateAncillaryMutation
export const useUpdateAncillary = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const dispatch = useDispatch();

  const [mutate] = useUpdateAncillaryMutation();

  const updateAncillaryWrapper = (ancillary_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(ancillary_data);
    } else {
      // For non-authenticated users, create an object with unwrap method
      // that mimics the RTK Query mutation result structure
      return {
        unwrap: async () => {
          // If the user is not auth'd, and weeks > 16, throw Error
          if (!is_authenticated && ancillary_data?.stop_wk > 16) {
            throw new Error('no_auth_weeks_limit_hit');
          }
          dispatch(
            updateAncillary({
              ...ancillary_data,
              date:
                typeof ancillary_data.date === 'string'
                  ? ancillary_data.date
                  : ancillary_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
            })
          );
          return { data: ancillary_data }; // Mimic the successful response format
        },
      };
    }
  };

  return [updateAncillaryWrapper];
};

// Custom Hook wrapper around useDeleteAncillaryMutation
export const useDeleteAncillary = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useDeleteAncillaryMutation();
  const dispatch = useDispatch();

  const deleteAncillaryWrapper = (ancillary_id) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(ancillary_id);
    } else {
      // For non-authenticated users, create an object with unwrap method
      // that mimics the RTK Query mutation result structure
      return {
        unwrap: async () => {
          dispatch(deleteAncillary(ancillary_id));
          return { data: ancillary_id }; // Mimic the successful response format
        },
      };
    }
  };

  return [deleteAncillaryWrapper];
};
