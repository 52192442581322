import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useUser } from 'features/auth/hooks';
import { useSelector } from 'react-redux';
import { useGetCycles } from 'features/cycles/hooks';

/**
 * Component that waits for authentication state to be resolved before rendering
 * its children or redirecting to login page.
 */
const WaitForAuthLayout = () => {
  const { isLoading: user_loading } = useUser();
  const { pathname } = useLocation();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const { data: cycles, isLoading: cycles_loading } = useGetCycles();

  if (user_loading || cycles_loading) {
    return <></>;
  }

  // If we are authenticated and on the privacy policy page
  // redirect to the first cycle if there is one, otherwise
  // redirect to the new cycle route
  if (is_authenticated && pathname === '/privacy-policy') {
    if (cycles?.length > 0) {
      return (
        <Navigate
          to={`/cycles/${cycles[0].id}/compounds?privacy_policy=true`}
          replace
        />
      );
    } else {
      return <Navigate to={`/cycles/new?privacy_policy=true`} replace />;
    }
  }

  // If we are not authenticated, pass through to Outlet
  return <Outlet />;
};

export default WaitForAuthLayout;
