import React from 'react';
import { lighterColorHash, desaturateColor } from 'helpers/colorHash';
import { LineSeries, GlyphSeries } from '@visx/xychart';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
import { useParams } from 'react-router-dom';

const BloodLines = ({
  cycle_start_date,
  weeks,
  max_release,
  max_blood_levels = {},
  bloods_by_marker = {},
}) => {
  const { data: bloods, isLoading, isFetching } = useGetBloodsByCycleId();
  const { menu_selected } = useParams();

  const getBloodLevel = (blood, marker) => {
    return blood[marker]
      ? ((max_release || max_blood_levels[marker]) / max_blood_levels[marker]) *
          blood[marker]
      : 'null';
  };

  const getBloodMarkerColor = (marker) => {
    // if menu_selected is not "blood-work" return desaturated color by 20%
    return menu_selected === 'blood-work'
      ? lighterColorHash(marker)
      : desaturateColor(lighterColorHash(marker), 10);
  };

  if (bloods?.length === 0 || isLoading || isFetching) return null;

  return (
    <>
      {Object.keys(bloods_by_marker).map((marker) => (
        <React.Fragment key={marker + '_blood_bars_fragment'}>
          <LineSeries
            key={marker + '_line'}
            dataKey={marker}
            data={bloods_by_marker[marker]}
            xAccessor={(blood) => blood.days}
            yAccessor={(blood) => getBloodLevel(blood, marker)}
            colorAccessor={(blood) => getBloodMarkerColor(marker)}
            opacity={menu_selected === 'blood-work' ? 1 : 0.5}
            strokeWidth={2}
          />
          <GlyphSeries
            key={marker + '_glyph'}
            dataKey={marker}
            data={bloods_by_marker[marker]}
            xAccessor={(blood) => blood.days}
            yAccessor={(blood) => getBloodLevel(blood, marker)}
            colorAccessor={(blood) => getBloodMarkerColor(marker)}
            opacity={menu_selected === 'blood-work' ? 1 : 0.5}
            strokeWidth={2}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default BloodLines;
