// src/components/Routes/NoAuthLayout.jsx
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUser } from 'features/auth/hooks';

/**
 * Layout component for authentication pages (login/signup)
 * Redirects authenticated users away from these pages
 */
function NoAuthLayout() {
  const { isLoading: user_loading, isFetching: user_fetching } = useUser();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const location = useLocation();

  if (user_loading) return <></>;

  // If user is already logged in, redirect them away
  if (is_authenticated) {
    // Redirect to previous location if available, otherwise to home
    const from = location.state?.from?.pathname || '/';
    return <Navigate to={from} replace />;
  }

  // Not authenticated, render the child routes
  return <Outlet />;
}

export default NoAuthLayout;
