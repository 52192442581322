import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';
import { dosesAPI } from './dosesAPI';

const initialState = {
  status: 'idle',
  message: '',
  doses_initialized: false,
  no_auth_doses_mutated: false,
  no_auth_doses: [],
};

export const drugSlice = createSlice({
  name: 'drugs',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetNoAuthDoses: (state, action) => {
      state.doses_initialized = !!action?.payload;
      state.no_auth_doses = action?.payload || [];
      state.no_auth_doses_mutated = false;
    },
    createDosage: (state, action) => {
      state.no_auth_doses.push(action.payload);
      state.no_auth_doses_mutated = true;
    },
    updateDosage: (state, action) => {
      const index = state.no_auth_doses.findIndex(
        (dose) => dose.id === action.payload.id
      );
      if (index === -1) {
        throw new Error(`Dosage id not found`);
      }
      state.no_auth_doses[index] = action.payload;
      state.no_auth_doses_mutated = true;
    },
    deleteDosage: (state, action) => {
      state.no_auth_doses = state.no_auth_doses.filter(
        (dose) => dose.id !== action.payload
      );
      state.no_auth_doses_mutated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      //getDosesByCycleId
      .addMatcher(
        dosesAPI.endpoints.getDosesByCycleId.matchFulfilled,
        (state, action) => {
          state.no_auth_doses = action.payload;
          state.doses_initialized = true;
        }
      )
      .addMatcher(dosesAPI.endpoints.createDosage.matchFulfilled, () => {
        customToast('success', 'Dosage created successfully');
        trackEvent({
          event: 'compound_created',
          category: 'CRUD',
          table: 'Compounds',
          action: 'CREATE',
          auth: true,
        });
      })
      .addMatcher(dosesAPI.endpoints.updateDosage.matchFulfilled, () => {
        customToast('success', 'Dosage updated successfully');
        trackEvent({
          event: 'compound_updated',
          category: 'CRUD',
          table: 'Compounds',
          action: 'UPDATE',
          auth: true,
        });
      })
      .addMatcher(dosesAPI.endpoints.deleteDosage.matchFulfilled, () => {
        customToast('success', 'Dosage deleted successfully');
        trackEvent({
          event: 'compound_deleted',
          category: 'CRUD',
          table: 'Compounds',
          action: 'DELETE',
          auth: true,
        });
      });
  },
});

export const selectDosesInfo = createSelector(
  (state) => state.drugs.doses_initialized,
  (state) => state.drugs.no_auth_doses_mutated,
  (doses_initialized, no_auth_doses_mutated) => {
    return {
      doses_initialized,
      no_auth_doses_mutated,
    };
  }
);

export const {
  reset,
  resetNoAuthDoses,
  createDosage,
  updateDosage,
  deleteDosage,
} = drugSlice.actions;
export default drugSlice.reducer;
