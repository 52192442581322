// React
import { useSelector, useDispatch } from 'react-redux';
// RTK Query API
import {
  useGetCyclesQuery,
  useGetCycleByIdQuery,
  useUpdateCycleMutation,
} from 'features/cycles/cyclesAPI';
// Redux
import { updateCycle } from 'features/cycles/cyclesSlice';
import { useParams } from 'react-router-dom';
import { useUser } from 'features/auth/hooks';
import { useRouteParams } from 'hooks/useRouteParams';

export const useGetCycles = () => {
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const { data: user, isLoading: user_loading } = useUser();

  const {
    data: cycles,
    isLoading,
    isFetching,
    isError,
  } = useGetCyclesQuery(user, {
    skip: user_loading || !user || !is_authenticated,
  });

  return {
    data: cycles,
    isLoading,
    isFetching,
    isError,
  };
};

export const useGetCycleById = () => {
  const { cycle_id: active_cycle_id } = useParams();
  const { isLoading: user_loading } = useUser();
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);
  const no_auth_cycle_mutated = useSelector(
    (state) => state.cycles.no_auth_cycle_mutated
  );
  const use_redux_cycle =
    active_cycle_id === 'no_auth_cycle' ||
    (no_auth_cycle_mutated && active_cycle_id === no_auth_cycle.id);

  const {
    data: cycle,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetCycleByIdQuery(active_cycle_id, {
    skip: user_loading || !active_cycle_id || use_redux_cycle,
  });

  return {
    data: use_redux_cycle ? no_auth_cycle : cycle,
    isSuccess: use_redux_cycle ? true : isSuccess,
    isLoading,
    isFetching,
    isError,
  };
};

export const useUpdateCycle = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdateCycleMutation();
  const dispatch = useDispatch();

  const updateCycleWrapper = (cycle_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(cycle_data);
    } else {
      // For non-authenticated users, create an object with unwrap method
      // that mimics the RTK Query mutation result structure
      return {
        unwrap: async () => {
          dispatch(
            updateCycle({
              id: cycle_data.id,
              title: cycle_data.title,
              start_date:
                typeof cycle_data.start_date === 'string'
                  ? cycle_data.start_date
                  : cycle_data.start_date.format('YYYY-MM-DD HH:mm:ssZ'),
              weeks: cycle_data.weeks,
              notes: cycle_data.notes,
              is_public: cycle_data.is_public,
            })
          );

          return cycle_data; // Mimic the successful response format
        },
      };
    }
  };

  return [updateCycleWrapper];
};
