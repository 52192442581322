import { useSelector } from 'react-redux';
import { useGetCycleById } from 'features/cycles/hooks';
import { useGetDosesByCycleId } from 'features/doses/hooks';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
import { useGetPeptidesByCycleId } from 'features/peptides/hooks';

/**
 * Custom hook that checks if any required data is uninitialized.
 * Returns true if any of the specified states are NOT initialized.
 *
 * @returns {boolean} - true if any data source is uninitialized, false if all are initialized
 */
export const useReduxSyncer = () => {
  const { isLoading: cycle_loading } = useGetCycleById();
  const { isLoading: doses_loading } = useGetDosesByCycleId();
  const { isLoading: bloods_loading } = useGetBloodsByCycleId();
  const { isLoading: ancillaries_loading } = useGetAncillariesByCycleId();
  const { isLoading: peptides_loading } = useGetPeptidesByCycleId();

  const cycle_initialized = useSelector(
    (state) => state.cycles.cycle_initialized
  );
  const doses_initialized = useSelector(
    (state) => state.drugs.doses_initialized
  );
  const ancillaries_initialized = useSelector(
    (state) => state.ancillaries.ancillaries_initialized
  );
  const peptides_initialized = useSelector(
    (state) => state.peptides.peptides_initialized
  );
  const bloods_initialized = useSelector(
    (state) => state.bloods.bloods_initialized
  );

  // Check if any source is NOT initialized (using negation)
  const isAnyUninitialized =
    !cycle_initialized ||
    !doses_initialized ||
    !ancillaries_initialized ||
    !peptides_initialized ||
    !bloods_initialized;

  const isAnyLoading =
    cycle_loading ||
    doses_loading ||
    bloods_loading ||
    ancillaries_loading ||
    peptides_loading;

  return { isAnyUninitialized, isAnyLoading };
};

export default useReduxSyncer;
