import React from 'react';
import { lighterColorHash } from 'helpers/colorHash';
import { useTheme, Chip, Box, Card, Stack } from '@mui/material';
import moment from 'moment';
import { PEPTIDES, BLOOD_UNITS } from '../../constants';

const PeptidesTooltip = ({ tooltipData, cycle_start_date }) => {
  const theme = useTheme();
  const data = tooltipData.datumByKey;
  const nearest_date = tooltipData.nearestDatum.datum.date;
  const date = data[Object.keys(data)[0]]?.datum?.date || null;
  const days = moment(Date.parse(date))
    .startOf('day')
    .diff(moment(cycle_start_date).startOf('day'), 'days');

  const cards = [];

  // Filters to only peptide data at the nearest date
  Object.keys(data)
    .filter(
      (key) =>
        Object.keys(PEPTIDES).includes(key.split('_')[0]) &&
        moment(Date.parse(data[key].datum.date)).startOf('day').toString() ===
          moment(Date.parse(nearest_date)).startOf('day').toString()
    )
    .forEach((name, i) => {
      cards.push(
        <Card key={name + '_card_' + i}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              background: lighterColorHash(name.split('_')[0]),
            }}>
            <Chip
              size='small'
              key={name + '_chip_name'}
              label={`${name.split('_')[0]}`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
            <Chip
              size='small'
              key={name + '_chip_level'}
              label={`${data[name].datum[name.split('_')[0]]} ${
                PEPTIDES[name.split('_')[0]]?.units || ''
              }`}
              sx={{
                borderRadius: '0px',
                backgroundColor: 'transparent',
              }}
            />
          </Box>
        </Card>
      );
    });

  return cards.length ? (
    <div>
      <Stack spacing={0.5}>
        <Card
          sx={{
            m: 0,
            p: 0,
            backgroundColor: theme.palette.primary.main,
          }}
          key={'tooltip-card'}>
          <Chip
            size='small'
            label={`${moment(Date.parse(date)).format('M/D')} • Wk ${
              Math.floor(days / 7) + 1
            } • Day ${days}`}
            sx={{
              borderRadius: '0px',
              width: '100%',
              color: theme.palette.primary.contrastText,
            }}
          />
        </Card>
        {cards}
      </Stack>
    </div>
  ) : (
    <></>
  );
};

export default PeptidesTooltip;
