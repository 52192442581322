import { api } from 'app/api';
import { supabase } from 'lib/api';
import { registerApiEndpoints } from 'helpers/apiRegistry';

export const peptidesAPI = api.injectEndpoints({
  feature_name: 'peptides',
  endpoints: (build) => ({
    getPeptides: build.query({
      queryFn: async () => {
        const { data, error } = await supabase
          .from('Peptides')
          .select('*')
          .order('updated_at', { ascending: false });
        return error ? { error } : { data };
      },
      providesTags: ['Peptide'],
    }),
    getPeptidesByCycleId: build.query({
      queryFn: async (cycle_id) => {
        const { data, error } = await supabase
          .from('Peptides')
          .select(`*`)
          .eq('cycle_id', cycle_id)
          .order('created_at');
        return error ? { error } : { data };
      },
      providesTags: ['Peptide'],
    }),
    createPeptide: build.mutation({
      queryFn: async (peptide_data_with_id) => {
        const { id, ...peptide_data } = peptide_data_with_id;

        const { data, error } = await supabase
          .from('Peptides')
          .insert([peptide_data])
          .select();
        return error || data.length ? { error } : { data };
      },
      invalidatesTags: ['Peptide'],
    }),
    updatePeptide: build.mutation({
      queryFn: async (peptide_data) => {
        const { data, error } = await supabase
          .from('Peptides')
          .update({ ...peptide_data, updated_at: new Date().toISOString() })
          .eq('id', peptide_data.id)
          .select();
        return error ? { error } : { data: data[0] };
      },
      invalidatesTags: ['Peptide'],
    }),
    deletePeptide: build.mutation({
      queryFn: async (id) => {
        const { data, error } = await supabase
          .from('Peptides')
          .delete()
          .eq('id', id)
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: ['Peptide'],
    }),
  }),
});

const peptides_endpoints = new Set([
  'getPeptides',
  'getPeptidesByCycleId',
  'createPeptide',
  'updatePeptide',
  'deletePeptide',
]);

registerApiEndpoints(peptides_endpoints, 'peptides');

export const {
  useGetPeptidesQuery,
  useGetPeptidesByCycleIdQuery,
  useCreatePeptideMutation,
  useUpdatePeptideMutation,
  useDeletePeptideMutation,
} = peptidesAPI;
