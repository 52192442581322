// React
import { useSelector, useDispatch } from 'react-redux';
// RTK Query API
import {
  useGetBloodsByCycleIdQuery,
  useCreateBloodResultMutation,
  useUpdateBloodResultMutation,
  useDeleteBloodResultMutation,
} from './bloodsAPI';
// Redux
import {
  createBloodResult,
  updateBloodResult,
  deleteBloodResult,
} from './bloodsSlice';
// Helpers
import { v4 as uuid_v4 } from 'uuid';
import { useUser } from 'features/auth/hooks';
import { useParams } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';

// Custom Hook wrapper around useGetBloodsByCycleIdQuery
export const useGetBloodsByCycleId = () => {
  const { cycle_id: active_cycle_id } = useParams();
  const { isLoading: user_loading } = useUser();
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);
  const no_auth_bloods_mutated = useSelector(
    (state) => state.bloods.no_auth_bloods_mutated
  );
  const no_auth_bloods = useSelector((state) => state.bloods.no_auth_bloods);
  const cycle_exists = useSelector((state) => state.cycles.cycle_exists);
  const use_redux_bloods =
    active_cycle_id === 'no_auth_cycle' ||
    (no_auth_bloods_mutated && active_cycle_id === no_auth_cycle.id);

  const {
    data: bloods,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetBloodsByCycleIdQuery(active_cycle_id, {
    skip:
      user_loading ||
      active_cycle_id === 'no_auth_cycle' ||
      !active_cycle_id ||
      !cycle_exists ||
      use_redux_bloods,
  });

  return {
    data: use_redux_bloods ? no_auth_bloods : bloods,
    isSuccess: use_redux_bloods ? true : isSuccess,
    isLoading,
    isFetching,
    isError,
  };
};

// CRUD operation hooks
export const useCreateBloodResult = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useCreateBloodResultMutation();
  const dispatch = useDispatch();

  const createBloodResultWrapper = async (blood_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(blood_data);
    } else {
      dispatch(
        createBloodResult({
          ...blood_data,
          id: uuid_v4(),
          date:
            typeof blood_data.date === 'string'
              ? blood_data.date
              : blood_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
        })
      );
      return Promise.resolve({ data: blood_data });
    }
  };

  return [createBloodResultWrapper];
};

export const useUpdateBloodResult = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdateBloodResultMutation();
  const dispatch = useDispatch();

  const updateBloodResultWrapper = (blood_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(blood_data);
    } else {
      return {
        unwrap: async () => {
          dispatch(
            updateBloodResult({
              ...blood_data,
              date:
                typeof blood_data.date === 'string'
                  ? blood_data.date
                  : blood_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
            })
          );
          return { data: blood_data };
        },
      };
    }
  };

  return [updateBloodResultWrapper];
};

export const useDeleteBloodResult = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useDeleteBloodResultMutation();
  const dispatch = useDispatch();

  const deleteBloodResultWrapper = (blood_id) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(blood_id);
    } else {
      return {
        unwrap: async () => {
          dispatch(deleteBloodResult(blood_id));
          return { data: blood_id };
        },
      };
    }
  };

  return [deleteBloodResultWrapper];
};
