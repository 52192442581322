// React
import { useSelector, useDispatch } from 'react-redux';
// RTK Query API
import {
  useGetJournalsByCycleIdQuery,
  useCreateJournalEntryMutation,
  useUpdateJournalEntryMutation,
  useDeleteJournalEntryMutation,
} from './journalAPI';
// Redux
import { useParams } from 'react-router-dom';
import { useUser } from 'features/auth/hooks';
import { useRouteParams } from 'hooks/useRouteParams';
// If you have journal slice actions, import them here
// import { createJournalEntry, updateJournalEntry, deleteJournalEntry } from './journalSlice';

// Custom Hook wrapper around useGetJournalsByCycleIdQuery
export const useGetJournalsByCycleId = () => {
  const { cycle_id: active_cycle_id } = useParams();
  const { isLoading: user_loading } = useUser();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const cycle_exists = useSelector((state) => state.cycles.cycle_exists);

  const {
    data: journals,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetJournalsByCycleIdQuery(active_cycle_id, {
    skip:
      user_loading ||
      !is_authenticated ||
      active_cycle_id === 'no_auth_cycle' ||
      !active_cycle_id ||
      !cycle_exists,
  });

  return {
    data: journals || [],
    isSuccess: is_authenticated ? isSuccess : true,
    isLoading,
    isFetching,
    isError,
  };
};

// CRUD operation hooks (since journal doesn't seem to have non-auth versions)
export const useCreateJournalEntry = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useCreateJournalEntryMutation();

  const createJournalEntryWrapper = async (entry_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(entry_data);
    }

    // For non-authenticated users, journals may not be supported
    return Promise.reject(new Error('Authentication required'));
  };

  return [createJournalEntryWrapper];
};

export const useUpdateJournalEntry = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdateJournalEntryMutation();

  const updateJournalEntryWrapper = (entry_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(entry_data);
    }

    // For non-authenticated users, journals may not be supported
    return {
      unwrap: async () => {
        throw new Error('Authentication required');
      },
    };
  };

  return [updateJournalEntryWrapper];
};

export const useDeleteJournalEntry = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useDeleteJournalEntryMutation();

  const deleteJournalEntryWrapper = (entry_id) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      return mutate(entry_id);
    }

    // For non-authenticated users, journals may not be supported
    return {
      unwrap: async () => {
        throw new Error('Authentication required');
      },
    };
  };

  return [deleteJournalEntryWrapper];
};
