import React from 'react';
import { lighterColorHash } from 'helpers/colorHash';
import { GlyphSeries } from '@visx/xychart';

const PeptideDots = ({
  max_release,
  max_peptide_levels = {},
  graph_data_by_peptide = {},
  overlap = {},
}) => {
  /**
   * Calculates the scaled level of an peptide based on its name.
   *
   * @param {Object} peptide - The peptide object containing various levels.
   * @param {string} name - The name of the peptide level to retrieve.
   * @param {number} offset - The offset to apply to the peptide level.
   * @returns {number|string} - The scaled level of the peptide or 'null' if the level does not exist.
   *
   * This function scales the peptide level by dividing the maximum release or maximum peptide level
   * by the maximum peptide level, and then multiplying by the peptide level. If the peptide level
   * does not exist, it returns 'null'.
   */
  const getPeptideLevel = (peptide, base_name) => {
    const ratio = peptide[base_name] / max_peptide_levels[base_name];
    const ratio_unique =
      overlap?.ratios?.[ratio] < 2 || !overlap?.ratios?.[ratio];
    const index = overlap?.ratios?.[ratio]?.indexOf(base_name);
    const scale_factor = ratio_unique ? 1 : 1 - Math.log(index + 1) * 0.05;

    return peptide[base_name]
      ? ((max_release || max_peptide_levels[base_name]) /
          max_peptide_levels[base_name]) *
          peptide[base_name] *
          scale_factor
      : 'null';
  };

  if (Object.keys(graph_data_by_peptide)?.length === 0) return null;

  return (
    <>
      {Object.keys(graph_data_by_peptide).map((name, i) => {
        const baseName = name.split('_')[0];

        return (
          <React.Fragment key={name + '_peptides_graph_fragment'}>
            <GlyphSeries
              key={name + '_peptides_glyph'}
              dataKey={name}
              data={graph_data_by_peptide[name]}
              xAccessor={(peptide) => peptide.days}
              yAccessor={(peptide) =>
                getPeptideLevel(peptide, name.split('_')[0])
              }
              colorAccessor={(peptide) => lighterColorHash(name.split('_')[0])}
              strokeWidth={2}
              renderGlyph={({ x, y, key, datum }) => {
                const num_duplicates =
                  overlap?.days?.[datum.days]?.[baseName]?.[datum[baseName]] ||
                  0;
                const size_shift = Math.max(num_duplicates - 1, 0);
                return (
                  <circle
                    key={key + '_peptides_circle'}
                    cx={x}
                    cy={y}
                    fill={lighterColorHash(baseName)}
                    // cap radius at 8
                    r={3 + (size_shift > 5 ? 5 : size_shift)}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default PeptideDots;
