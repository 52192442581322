import React from 'react';
import { lighterColorHash } from 'helpers/colorHash';
import { GlyphSeries } from '@visx/xychart';

const AncillaryLines = ({
  max_release,
  max_ancillary_levels = {},
  graph_data_by_ancillary = {},
  overlap = {},
}) => {
  /**
   * Calculates the scaled level of an ancillary based on its name.
   *
   * @param {Object} ancillary - The ancillary object containing various levels.
   * @param {string} name - The name of the ancillary level to retrieve.
   * @param {number} offset - The offset to apply to the ancillary level.
   * @returns {number|string} - The scaled level of the ancillary or 'null' if the level does not exist.
   *
   * This function scales the ancillary level by dividing the maximum release or maximum ancillary level
   * by the maximum ancillary level, and then multiplying by the ancillary level. If the ancillary level
   * does not exist, it returns 'null'.
   */
  const getAncillaryLevel = (ancillary, base_name) => {
    const ratio = ancillary[base_name] / max_ancillary_levels[base_name];
    const ratio_unique =
      overlap?.ratios?.[ratio] < 2 || !overlap?.ratios?.[ratio];
    const index = overlap?.ratios?.[ratio]?.indexOf(base_name);
    const scale_factor = ratio_unique ? 1 : 1 - Math.log(index + 1) * 0.05;

    return ancillary[base_name]
      ? ((max_release || max_ancillary_levels[base_name]) /
          max_ancillary_levels[base_name]) *
          ancillary[base_name] *
          scale_factor
      : 'null';
  };

  if (Object.keys(graph_data_by_ancillary)?.length === 0) return null;

  return (
    <>
      {Object.keys(graph_data_by_ancillary).map((name, i) => {
        const baseName = name.split('_')[0];

        return (
          <React.Fragment key={name + '_ancillaries_graph_fragment'}>
            <GlyphSeries
              key={name + '_ancillaries_glyph'}
              dataKey={name}
              data={graph_data_by_ancillary[name]}
              xAccessor={(ancillary) => ancillary.days}
              yAccessor={(ancillary) =>
                getAncillaryLevel(ancillary, name.split('_')[0])
              }
              colorAccessor={(ancillary) =>
                lighterColorHash(name.split('_')[0])
              }
              strokeWidth={2}
              renderGlyph={({ x, y, key, datum }) => {
                const num_duplicates =
                  overlap?.days?.[datum.days]?.[baseName]?.[datum[baseName]] ||
                  0;
                const size_shift = Math.max(num_duplicates - 1, 0);
                return (
                  <circle
                    key={key + '_ancillaries_circle'}
                    cx={x}
                    cy={y}
                    fill={lighterColorHash(baseName)}
                    // cap radius at 8
                    r={3 + (size_shift > 5 ? 5 : size_shift)}
                  />
                );
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default AncillaryLines;
