// MUI
import { Dialog, DialogContent } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { dark_theme } from '../lib/theme';
// React
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// Components
import {
  DoseList,
  CycleGraph,
  DrugCardForm,
  CycleInfo,
  AncillariesList,
  AncillaryForm,
  PeptidesList,
  PeptideForm,
  BloodList,
  BloodForm,
  PopoverAuth,
  JournalList,
  Footer,
  About,
} from 'components';
// Helpers
import { customToast } from 'helpers/customToast';
// Custom Hooks
import { useGetCycleById } from 'features/cycles/hooks';
import { useGetDosesByCycleId } from 'features/doses/hooks';
import { useGetBloodsByCycleId } from 'features/bloods/hooks';
import { useGetAncillariesByCycleId } from 'features/ancillaries/hooks';
import { useGetPeptidesByCycleId } from 'features/peptides/hooks';
// Components
import { PrivacyPolicy } from './';
// Auth
import { useAuth } from 'hooks/useAuth';
import { useRouteParams } from 'hooks/useRouteParams';
import { useUser } from 'features/auth/hooks';
// Redux
import {
  refreshGlobalRedux,
  selectGlobalInfo,
} from 'features/global/globalSlice';
import useReduxSyncer from 'hooks/useReduxSyncer';
function Dashboard() {
  // Hooks
  const { cycle_id, menu_selected } = useParams();
  const {
    creating,
    user_owns_cycle,
    show_privacy_policy,
    closePrivacyPolicy,
    show_about_modal,
    closeAboutModal,
  } = useRouteParams();
  const { data: user, isLoading: user_loading } = useUser();
  const { show_auth_modal, signup_open, closeAuth } = useAuth();
  const { isAnyLoading, isAnyUninitialized } = useReduxSyncer();
  // Redux
  const dispatch = useDispatch();
  const { actions_counter } = useSelector(selectGlobalInfo);
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);
  // RTK
  const {
    data: cycle,
    isLoading: cycle_loading,
    isFetching: cycle_fetching,
  } = useGetCycleById();
  const { data: doses, isLoading: doses_loading } = useGetDosesByCycleId();
  const { data: bloods, isLoading: bloods_loading } = useGetBloodsByCycleId();
  const { data: ancillaries, isLoading: ancillaries_loading } =
    useGetAncillariesByCycleId();
  const { data: peptides, isLoading: peptides_loading } =
    useGetPeptidesByCycleId();

  // MUI
  const smallScreen = useMediaQuery(dark_theme.breakpoints.down('sm'));
  const extraLargeScreen = useMediaQuery(dark_theme.breakpoints.up('xl'));
  // Component State
  const [dialog_open, setDialogOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [current_item_id, setCurrentItemId] = useState('');

  useEffect(() => {
    // TODO: Revisist this logic if we add other types of mutable cycles
    // This fires for any mutable cycle (i.e. right now is_public cycles)
    // ? ATM, if the cycle is_public is false, it is NEVER mutable...
    // Dispatch empty refreshGlobalRedux to reset initialization flags
    if (
      (cycle?.is_public && !cycle_fetching && !user_owns_cycle) ||
      (cycle_id === 'no_auth_cycle' && actions_counter === 0)
    ) {
      dispatch(refreshGlobalRedux());
    }

    return () => {};
  }, [cycle_id, cycle?.id, cycle_fetching, user_owns_cycle]);

  // Refresh global redux state when we load new data
  // and the user is on a public cycle, meaning they can mutate redux
  useEffect(() => {
    if (
      !user_owns_cycle &&
      isAnyUninitialized &&
      !isAnyLoading &&
      (cycle || doses || ancillaries || peptides || bloods)
    ) {
      dispatch(
        refreshGlobalRedux({
          cycle,
          doses,
          ancillaries,
          peptides,
          bloods,
        })
      );
    }
  }, [
    cycle,
    doses,
    ancillaries,
    peptides,
    bloods,
    user_owns_cycle,
    isAnyLoading,
    isAnyUninitialized,
  ]);

  // Effect to close dialog when navigating away
  useEffect(() => {
    handleDialogClose();
  }, [cycle_id, menu_selected]);

  const handleEdit = (id) => {
    !id && customToast('error', 'No item selected');
    setCurrentItemId(id);
    setEditing(true);
    setDialogOpen(true);
  };

  const clearEditing = () => {
    setEditing(false);
    setCurrentItemId('');
  };

  const handleCreate = () => {
    if (creating) {
      customToast('error', 'Create a cycle first');
    } else {
      clearEditing();
      setDialogOpen(true);
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTimeout(clearEditing, 300);
  };

  const getDialogDimensions = () => {
    if (smallScreen) {
      // Define dimensions for extra small screens
      return { height: '100%', width: '100%' };
    }
    // Define dimensions for other screens
    return { height: signup_open ? 650 : 600, width: signup_open ? 500 : 450 };
  };

  const dialogDimensions = getDialogDimensions();

  if (user_loading || cycle_loading || cycle_fetching) {
    return <></>;
  }

  return (
    <div className='dashboard'>
      <CycleInfo />
      <div className='drug-form'>
        {
          {
            compounds: (
              <DrugCardForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_drug_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
            ancillaries: (
              <AncillaryForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_ancillary_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
            peptides: (
              <PeptideForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_peptide_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
            'blood-work': (
              <BloodForm
                handleDialogClose={handleDialogClose}
                clearEditing={clearEditing}
                current_blood_id={current_item_id}
                open={dialog_open}
                editing={editing}
              />
            ),
          }[menu_selected]
        }
      </div>

      {!creating &&
        {
          compounds: (
            <div className='drug-list'>
              <DoseList
                doses={doses}
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
          ancillaries: (
            <div className='ancillaries-list'>
              <AncillariesList
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
          peptides: (
            <div className='peptides-list'>
              <PeptidesList
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
          'blood-work': (
            <div className='blood-work'>
              <BloodList handleCreate={handleCreate} handleEdit={handleEdit} />
            </div>
          ),
          journal: (
            <div className='journal'>
              <JournalList
                handleCreate={handleCreate}
                handleEdit={handleEdit}
              />
            </div>
          ),
        }[menu_selected]}

      {(doses?.length > 0 ||
        peptides?.length > 0 ||
        bloods?.length > 0 ||
        ancillaries?.length > 0) &&
        !creating &&
        !isAnyLoading && <CycleGraph />}

      {!user_loading && !user && <Footer />}

      <ThemeProvider theme={dark_theme}>
        <Dialog
          open={show_auth_modal}
          onClose={closeAuth}
          fullScreen={smallScreen}
          maxWidth={false}
          closeAfterTransition={false}
          sx={{
            '& .MuiDialog-paper': {
              ...dialogDimensions,
              margin: smallScreen ? 0 : 'auto',
            },
          }}>
          <DialogContent
            sx={{
              height: '100%',
              width: '100%',
              backgroundColor: dark_theme.palette.background.default,
            }}>
            <PopoverAuth />
          </DialogContent>
        </Dialog>
      </ThemeProvider>
      <Dialog
        open={!!show_privacy_policy}
        onClose={closePrivacyPolicy}
        closeAfterTransition={false}>
        <DialogContent sx={{ pt: 0 }}>
          <PrivacyPolicy />
        </DialogContent>
      </Dialog>
      {/* About Dialog */}
      <Dialog
        open={!!show_about_modal}
        onClose={closeAboutModal}
        closeAfterTransition={false}>
        <DialogContent>
          <About />
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Dashboard;
