// src/components/Shared/ShareDialog.jsx
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Divider,
  TextField,
  Button,
  Tooltip,
} from '@mui/material';
import { Close, ContentCopy } from '@mui/icons-material';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  WhatsappIcon,
  RedditIcon,
  EmailIcon,
  XIcon,
} from 'react-share';

const ShareDialog = ({
  open,
  onClose,
  title,
  url = window.location.href,
  description,
}) => {
  const [copied, setCopied] = React.useState(false);

  const fallbackCopyTextToClipboard = (text) => {
    // Create the textarea element
    const textArea = document.createElement('textarea');
    textArea.value = text;

    // Make it visible but offscreen for iOS
    textArea.style.position = 'fixed';
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.opacity = '0';
    textArea.style.zIndex = '-1';

    // For iOS Safari
    textArea.contentEditable = true;
    textArea.readOnly = false;

    document.body.appendChild(textArea);

    // Create a range to select the text
    const range = document.createRange();
    range.selectNodeContents(textArea);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);

    // iOS specific: Use setSelectionRange
    textArea.setSelectionRange(0, 999999);

    try {
      const successful = document.execCommand('copy');
      setCopied(successful);
      setTimeout(() => setCopied(false), 2000);

      if (!successful) {
        // If copy command fails, show manual instructions
        let message =
          'Copy failed. Select this text and copy manually: ' + text;
        alert(message);
      }
    } catch (err) {
      console.error('Fallback copy failed:', err);
      alert(
        'Could not copy automatically. Please copy the link manually: ' + text
      );
    }

    document.body.removeChild(textArea);
  };

  const handleCopyLink = async () => {
    try {
      if (navigator.clipboard && navigator.clipboard.writeText) {
        await navigator.clipboard.writeText(url);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      } else {
        fallbackCopyTextToClipboard(url);
      }
    } catch (err) {
      console.error('Clipboard API failed:', err);
      fallbackCopyTextToClipboard(url);
    }
  };

  const shareText = `Check out this cycle: ${title}`;

  return (
    <Dialog open={open} onClose={onClose} maxWidth='xs' fullWidth>
      <DialogTitle>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>Share Cycle</Typography>
          <IconButton onClick={onClose} size='small'>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box display='flex' justifyContent='space-around' my={2}>
          <TwitterShareButton url={url} title={shareText}>
            <XIcon size={40} round />
          </TwitterShareButton>

          <FacebookShareButton url={url} quote={shareText}>
            <FacebookIcon size={40} round />
          </FacebookShareButton>

          <WhatsappShareButton url={url} title={shareText}>
            <WhatsappIcon size={40} round />
          </WhatsappShareButton>

          <RedditShareButton url={url} title={shareText}>
            <RedditIcon size={40} round />
          </RedditShareButton>

          <EmailShareButton
            url={url}
            subject={`Just1Cycle: ${title}`}
            body={shareText}>
            <EmailIcon size={40} round />
          </EmailShareButton>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Typography variant='body2' gutterBottom>
          Or copy link:
        </Typography>

        <Box display='flex' mt={1}>
          <TextField
            fullWidth
            variant='outlined'
            size='small'
            value={url}
            InputProps={{ readOnly: true }}
          />
          <Button
            variant='contained'
            color={'primary'}
            sx={{ ml: 1 }}
            onClick={handleCopyLink}>
            {copied ? '✓' : <ContentCopy />}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
