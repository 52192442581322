import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';

export const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const [searchParams, setSearchParams] = useSearchParams();

  // Extract auth-related URL parameters
  const auth_open = searchParams.get('auth') === 'true';
  const login_open =
    searchParams.get('login') === 'true' || location.pathname === '/login';

  // Calculate if auth modal should be shown
  const show_auth_modal =
    !is_authenticated &&
    (auth_open ||
      location.pathname === '/login' ||
      location.pathname === '/signup');

  const signup_open = location.pathname === '/signup' || !login_open;

  const openLogin = (origin = '') => {
    if (origin === 'header' || location.pathname === '/signup') {
      navigate('/login');
    } else {
      setSearchParams({ auth: 'true', login: 'true' });
    }

    trackEvent({
      event: 'login_opened',
      category: 'Auth',
      action: 'OPEN',
      origin: origin || '',
      url: location.pathname,
      env: process.env.NODE_ENV,
      auth: false,
    });
  };

  const openSignUp = (origin = '') => {
    if (origin === 'header' || location.pathname === '/login') {
      navigate('/signup');
    } else {
      // Use setSearchParams to update URL parameters
      setSearchParams({ auth: 'true', login: 'false' });
    }

    if (origin === 'no_auth_weeks_limit_hit') {
      customToast('info', 'Sign Up or Login to add more than 16 weeks');
    }

    trackEvent({
      event: 'sign_up_opened',
      category: 'Auth',
      action: 'OPEN',
      origin: origin || '',
      url: location.pathname,
      env: process.env.NODE_ENV,
      auth: false,
    });
  };

  const closeAuth = () => {
    if (location.pathname === '/login' || location.pathname === '/signup') {
      if (is_authenticated) {
        navigate('/');
      } else {
        navigate('/cycles/no_auth_cycle/compounds');
      }
    } else {
      // Use setSearchParams to remove parameters
      const newParams = new URLSearchParams(searchParams);
      newParams.delete('auth');
      newParams.delete('login');
      setSearchParams(newParams);
    }
  };

  const switchAuthType = () => {
    if (location.pathname === '/login') {
      navigate('/signup', { replace: true });
      return;
    } else if (location.pathname === '/signup') {
      navigate('/login');
      return;
    }

    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set('login', login_open ? 'false' : 'true');
      return newParams;
    });
  };

  return {
    // Auth state
    auth_open,
    login_open,
    signup_open,
    show_auth_modal,

    // Auth actions
    openLogin,
    openSignUp,
    closeAuth,
    switchAuthType,
  };
};
