import { createSlice } from '@reduxjs/toolkit';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';
import { bloodsAPI } from './bloodsAPI';
import { createSelector } from 'reselect';
import { NO_AUTH_BLOODS } from 'constants';

const initialState = {
  all: [],
  hidden_results: {},
  hidden_markers: {},
  bloods_initialized: false,
  no_auth_bloods_mutated: false,
  no_auth_bloods: NO_AUTH_BLOODS,
};

export const bloodsSlice = createSlice({
  name: 'bloods',
  initialState,
  reducers: {
    resetNoAuthBloods: (state, action) => {
      state.bloods_initialized = !!action?.payload;
      state.no_auth_bloods = action?.payload || NO_AUTH_BLOODS;
      state.no_auth_bloods_mutated = false;
    },
    createBloodResult: (state, action) => {
      state.no_auth_bloods.push(action.payload);
      state.no_auth_bloods_mutated = true;
    },
    updateBloodResult: (state, action) => {
      const index = state.no_auth_bloods.findIndex(
        (blood) => blood.id === action.payload.id
      );
      if (index === -1) {
        throw new Error(`Blood Result id not found`);
      }
      state.no_auth_bloods[index] = action.payload;
      state.no_auth_bloods_mutated = true;
    },
    deleteBloodResult: (state, action) => {
      state.no_auth_bloods = state.no_auth_bloods.filter(
        (blood) => blood.id !== action.payload
      );
      state.no_auth_bloods_mutated = true;
    },
    clearFilters: (state) => {
      state.hidden_results = {};
      state.hidden_markers = {};
    },
    toggleBlood: (state, { payload }) => {
      if (payload in state.hidden_results) {
        delete state.hidden_results[payload];
      } else {
        state.hidden_results[payload] = true;
      }
    },
    toggleMarker: (state, { payload }) => {
      if (payload in state.hidden_markers) {
        delete state.hidden_markers[payload];
      } else {
        state.hidden_markers[payload] = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        bloodsAPI.endpoints.getBloodsByCycleId.matchFulfilled,
        (state, { payload }) => {
          state.no_auth_bloods = payload;
          state.bloods_initialized = true;
        }
      )
      .addMatcher(bloodsAPI.endpoints.createBloodResult.matchFulfilled, () => {
        customToast('success', 'Blood Result created successfully');
        trackEvent({
          event: 'bloods_created',
          category: 'CRUD',
          table: 'Bloods',
          action: 'CREATE',
          auth: true,
        });
      })
      .addMatcher(bloodsAPI.endpoints.updateBloodResult.matchFulfilled, () => {
        customToast('success', 'Blood Result updated successfully');
        trackEvent({
          event: 'bloods_updated',
          category: 'CRUD',
          table: 'Bloods',
          action: 'UPDATE',
          auth: true,
        });
      })
      .addMatcher(bloodsAPI.endpoints.deleteBloodResult.matchFulfilled, () => {
        customToast('success', 'Blood Result deleted successfully');
        trackEvent({
          event: 'bloods_deleted',
          category: 'CRUD',
          table: 'Bloods',
          action: 'DELETE',
          auth: true,
        });
      });
  },
});

// Creates a selector that returns the hidden_results and hidden_markers
export const selectBloodsInfo = createSelector(
  (state) => state.bloods.hidden_results,
  (state) => state.bloods.hidden_markers,
  (hidden_results, hidden_markers) => {
    return {
      hidden_results,
      hidden_markers,
    };
  }
);

export const {
  resetNoAuthBloods,
  createBloodResult,
  updateBloodResult,
  deleteBloodResult,
  clearFilters,
  toggleBlood,
  toggleMarker,
} = bloodsSlice.actions;
export default bloodsSlice.reducer;
