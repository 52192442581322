import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUser } from 'features/auth/hooks';

function ProtectedLayout() {
  const { isLoading: user_loading, isFetching: user_fetching } = useUser();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const location = useLocation();

  if (user_loading || user_fetching) return <></>;

  if (!is_authenticated) {
    return <Navigate to='/' state={{ from: location }} replace />;
  }

  return <Outlet />;
}

export default ProtectedLayout;
