import { STEP } from 'constants';
// computeOffset = (cycle_start_date, start_wk, frequency, date, step) =>
//    returns # of days from cycle start to first dose
import { computeOffset } from 'helpers/roid_calc_v2';
import moment from 'moment';

// Shared overlap objets
// Tracks ancillary overlaps for each day and sum of doses
let overlap_days = {};
let unique_doses = {};

// max_levels is an object with the highest value for each ancillary by name
//    ex: { HGH: 10 }
//    This is used to scale the y-axis for each ancillary according to it's max value
// ancillary_graph_data is an object with an array of objects for each ancillary by name
//    each object in an array has a date and the value for ancillary
//    ex: { date: '2021-01-01', T: 1000 } for TSH
//    This keeps the data set for each ancillary separate so that we can
//    easily map over each ancillary and create a line for each one
export const ancillariesPrepper = (
  ancillaries,
  cycle_start_date,
  weeks,
  hidden_ancillaries = {}
) => {
  if (ancillaries?.length) {
    let max_levels = {};
    let graph_arrays_by_ancillary = {};
    overlap_days = {};
    unique_doses = {};

    // create a ghost array (bands) with zero values for each day in the cycle
    const total_days = weeks * 7;
    const bands = Array.from(
      { length: Math.ceil(total_days / STEP) - 1 },
      (_, i) => ({
        date: moment(cycle_start_date)
          .add(i * STEP, 'days')
          .format('MM/DD/YYYY'),
        value: 0,
        days: (i * STEP).toFixed(1),
      })
    );
    graph_arrays_by_ancillary['BANDS'] = bands;

    const cycle_end_date = moment(cycle_start_date).add(total_days - 1, 'days');

    ancillaries?.forEach((ancillary, i) => {
      const { id, name, dose, date, frequency } = ancillary;

      // create the graph data for each ancillary
      if (!(name in hidden_ancillaries)) {
        if (!frequency) {
          let start_moment = moment(date).startOf('day');

          if (
            start_moment.isSameOrAfter(
              moment(cycle_start_date).startOf('day')
            ) &&
            start_moment.isBefore(cycle_end_date)
          ) {
            let days = start_moment
              .diff(moment(cycle_start_date).startOf('day'), 'days')
              .toFixed(1);
            // update overlap_days for single dose ancillary
            update_overlap(days, name, dose);

            if (graph_arrays_by_ancillary[name + '_' + id]) {
              graph_arrays_by_ancillary[name + '_' + id].push({
                date: start_moment.format('MM/DD/YYYY'),
                [name]: dose,
                days: days,
              });
            } else {
              graph_arrays_by_ancillary[name + '_' + id] = [
                {
                  date: moment(date).startOf('day').format('MM/DD/YYYY'),
                  [name]: dose,
                  days: days,
                },
              ];
            }
          }
        } else {
          graph_arrays_by_ancillary[name + '_' + id] = createGraphData(
            ancillary,
            cycle_start_date,
            bands.length
          );
        }
      }

      // reset the max for the ancillary if necessary
      if (name in max_levels) {
        if (dose > max_levels[name]) {
          max_levels[name] = dose;
        }
      } else {
        max_levels[name] = dose;
      }
    });

    let unique_ratios = {};
    Object.keys(unique_doses).forEach((dose) => {
      // for each dose there will be a list of ancillaries
      Object.keys(unique_doses[dose]).forEach((ancillary) => {
        const ratio = dose / max_levels[ancillary];
        // if the ratio is in the unique_ratios object, add the ancillary to the object
        if (!unique_ratios[ratio]) {
          unique_ratios[ratio] = [ancillary];
        } else {
          unique_ratios[ratio].push(ancillary);
        }
      });
    });

    Object.entries(unique_ratios).map(([key, ratio]) => {
      if (ratio.length > 1) {
        //sort
        return [key, ratio.sort()];
      }
      return [key, ratio];
    });

    return [
      max_levels,
      graph_arrays_by_ancillary,
      { days: overlap_days, ratios: unique_ratios },
    ];
  }
  return [{}, {}, {}];
};

const update_overlap = (days, name, dose) => {
  // keep a count of the duplicate doses for that ancillary, that day
  if (!overlap_days[days]) {
    overlap_days[days] = {};
  }
  if (!overlap_days[days][name]) {
    overlap_days[days][name] = { [dose]: 1, sum: dose };
  } else {
    if (overlap_days[days][name][dose]) {
      overlap_days[days][name][dose] += 1;
    } else {
      overlap_days[days][name][dose] = 1;
    }
    overlap_days[days][name].sum += dose;
  }
  // keep a list of ancillaries at each unique dose
  if (!unique_doses[dose]) {
    unique_doses[dose] = {};
  }
  if (!unique_doses[dose][name]) {
    unique_doses[dose][name] = 1;
  } else {
    unique_doses[dose][name] += 1;
  }
};

// return an array of objects with the date and dose for each step in repeat dose
const createGraphData = (
  ancillary,
  cycle_start_date,
  cycle_length_in_steps
) => {
  const { id, name, dose, date, frequency, start_wk, stop_wk } = ancillary;

  let graph_data_array = [];
  const steps_offset = computeOffset(
    cycle_start_date,
    start_wk,
    frequency,
    date,
    STEP
  );

  // Calculate total shots based on frequency type
  let doses =
    frequency === 69
      ? 3 * (stop_wk - start_wk + 1) // MWF = 3 shots per week
      : Math.round(((stop_wk - start_wk + 1) * 7) / frequency);

  for (
    let i = steps_offset, shots_left = doses, mwf_counter = 0;
    i < cycle_length_in_steps && shots_left > 0;
    i +=
      frequency === 69
        ? (mwf_counter % 3 === 2 ? 3 : 2) / STEP
        : frequency / STEP,
      shots_left--,
      mwf_counter++
  ) {
    const days = (i * STEP).toFixed(1);
    graph_data_array.push({
      date: moment(cycle_start_date)
        .startOf('day')
        .add(i * STEP, 'days')
        .format('MM/DD/YYYY'),
      days: days,
      [name]: dose,
    });
    // update overlap_days for that day, that ancillary, that dose
    update_overlap(days, name, dose);
  }

  return graph_data_array;
};
