// React
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// RTK Query API
import {
  useGetDosesByCycleIdQuery,
  useCreateDosageMutation,
  useDeleteDosageMutation,
  useUpdateDosageMutation,
} from 'features/doses/dosesAPI';
// Redux
import {
  createDosage,
  updateDosage,
  deleteDosage,
} from 'features/doses/dosesSlice';
// Helpers
import { v4 as uuid_v4 } from 'uuid';
import { useUser } from 'features/auth/hooks';
import { useRouteParams } from 'hooks/useRouteParams';

// Custom Hook wrapper around useGetDoesByCycleIdQuery
export const useGetDosesByCycleId = () => {
  const { cycle_id: active_cycle_id } = useParams();
  const { isLoading: user_loading } = useUser();
  const no_auth_cycle = useSelector((state) => state.cycles.no_auth_cycle);
  const no_auth_doses_mutated = useSelector(
    (state) => state.drugs.no_auth_doses_mutated
  );
  const no_auth_doses = useSelector((state) => state.drugs.no_auth_doses);
  const cycle_exists = useSelector((state) => state.cycles.cycle_exists);
  const use_redux_doses =
    active_cycle_id === 'no_auth_cycle' ||
    (no_auth_doses_mutated && active_cycle_id === no_auth_cycle.id);

  const {
    data: doses,
    isSuccess,
    isLoading,
    isFetching,
    isError,
  } = useGetDosesByCycleIdQuery(active_cycle_id, {
    skip:
      user_loading ||
      active_cycle_id === 'no_auth_cycle' ||
      !active_cycle_id ||
      !cycle_exists ||
      use_redux_doses,
  });

  return {
    data: use_redux_doses ? no_auth_doses : doses,
    isSuccess: use_redux_doses ? true : isSuccess,
    isLoading,
    isFetching,
    isError,
  };
};

// Custom Hook wrapper around useCreateDosageMutation
export const useCreateDosage = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useCreateDosageMutation();
  const dispatch = useDispatch();

  const createDosageWrapper = async (dosage_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(dosage_data);
    } else {
      // Dispatch the create dosage action
      dispatch(
        createDosage({
          ...dosage_data,
          id: uuid_v4(),
          created_at: new Date().toISOString(),
          date:
            typeof dosage_data.date === 'string'
              ? dosage_data.date
              : dosage_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
        })
      );
      return Promise.resolve({ data: dosage_data });
    }
  };

  return [createDosageWrapper];
};

// Custom Hook wrapper around useUpdateDosageMutation
export const useUpdateDosage = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useUpdateDosageMutation();
  const dispatch = useDispatch();

  const updateDosageWrapper = (dosage_data) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(dosage_data);
    } else {
      // For non-authenticated users, create an object with unwrap method
      // that mimics the RTK Query mutation result structure
      return {
        unwrap: async () => {
          // If the user is not auth'd, and weeks > 16, throw Error
          if (!is_authenticated && dosage_data?.stop_wk > 16) {
            throw new Error('no_auth_weeks_limit_hit');
          }
          // Log the type of each field in dosage_data
          dispatch(
            updateDosage({
              ...dosage_data,
              date:
                typeof dosage_data.date === 'string'
                  ? dosage_data.date
                  : dosage_data.date.format('YYYY-MM-DD HH:mm:ssZ'),
            })
          );
          return { data: dosage_data }; // Mimic the successful response format
        },
      };
    }
  };

  return [updateDosageWrapper];
};

// Custom Hook wrapper around useDeleteDosageMutation
export const useDeleteDosage = () => {
  const { creating, user_owns_cycle } = useRouteParams();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const [mutate] = useDeleteDosageMutation();
  const dispatch = useDispatch();

  const deleteDosageWrapper = (dosage_id) => {
    if (is_authenticated && (user_owns_cycle || creating)) {
      // Return the RTK Query mutation
      return mutate(dosage_id);
    } else {
      // For non-authenticated users, create an object with unwrap method
      // that mimics the RTK Query mutation result structure
      return {
        unwrap: async () => {
          dispatch(deleteDosage(dosage_id));
          return { data: dosage_id }; // Mimic the successful response format
        },
      };
    }
  };

  return [deleteDosageWrapper];
};
