// MUI
import { TextField, Box, IconButton } from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import {
  TimelineItem,
  TimelineSeparator,
  TimelineContent,
  TimelineConnector,
  TimelineOppositeContent,
  TimelineDot,
} from '@mui/lab';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
// React
import { useSelector, shallowEqual } from 'react-redux';
import { useState } from 'react';
// Helpers
import moment from 'moment';
import { customToast } from 'helpers/customToast';
import { useParams } from 'react-router-dom';
import { useRouteParams } from 'hooks/useRouteParams';
// Auth
import { useAuth } from 'hooks/useAuth';
import {
  useCreateJournalEntry,
  useUpdateJournalEntry,
} from 'features/journal/hooks';

const JournalForm = ({
  editing = false,
  journal = { date: moment(), text: '' },
  cancelForm,
}) => {
  // Hooks
  const { cycle_id: active_cycle_id } = useParams();
  const { openSignUp } = useAuth();
  const { user_owns_cycle } = useRouteParams();
  // Redux
  const user_id = useSelector((state) => state.auth.user_id);
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  // RTK Query
  const [createJournalEntry] = useCreateJournalEntry();
  const [updateJournalEntry] = useUpdateJournalEntry();
  // Local State
  const [form_data, setFormData] = useState(journal);

  const handleFormDataChange = (e) => {
    setFormData({
      ...form_data,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = (date) => {
    setFormData({
      ...form_data,
      date,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!is_authenticated) {
      cancelForm();
      openSignUp('no_auth_actions_limit_reached');
      customToast('info', 'Sign Up or Login to save your journal entries');
      return;
    }

    // Validation
    if (!active_cycle_id) {
      customToast('error', 'No Cycle ID');
      return;
    } else if (!form_data.date) {
      customToast('error', 'No Date');
      return;
    } else if (!form_data.text) {
      customToast('error', 'No Text');
      return;
    }

    if (editing && form_data?.id && shallowEqual(form_data, journal)) {
      //compare newVals to og_drug
      //if they are the same, return
      cancelForm();
      customToast('info', 'No Changes');
      return;
    } else if (!user_owns_cycle) {
      cancelForm();
      customToast('info', 'Save this cycle to add journal entries');
      return;
    } else if (editing && form_data?.id) {
      cancelForm();
      try {
        await updateJournalEntry({
          ...form_data,
          cycle_id: active_cycle_id,
          id: journal.id,
          updated_at: new Date().toISOString(),
        }).unwrap();
      } catch (error) {
        if (!is_authenticated) {
          window.location.reload();
        }
        return;
      }
    } else {
      cancelForm();
      try {
        await createJournalEntry({
          ...form_data,
          cycle_id: active_cycle_id,
          user_id,
        }).unwrap();
      } catch (error) {
        if (!is_authenticated) {
          window.location.reload();
        }
        return;
      }
    }
  };

  return (
    <TimelineItem key={journal.id + '_timeline_item'}>
      <TimelineOppositeContent sx={{ minWidth: '180px' }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DesktopDatePicker
            required
            name='date'
            label='Date'
            inputFormat='MM/DD/YYYY'
            value={form_data.date}
            onChange={(value) => handleDateChange(value)}
            renderInput={(params) => <TextField variant='filled' {...params} />}
          />
        </LocalizationProvider>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <form onSubmit={onSubmit}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              name='text'
              size='small'
              variant='outlined'
              placeholder='Add journal entry'
              multiline
              minRows={3}
              maxRows={8}
              value={form_data.text}
              onChange={handleFormDataChange}
              sx={{ width: '100%' }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <IconButton
                variant='contained'
                onClick={() => {
                  cancelForm();
                }}>
                <Close />
              </IconButton>
              <IconButton variant='contained' type='submit'>
                <Check />
              </IconButton>
            </Box>
          </Box>
        </form>
      </TimelineContent>
    </TimelineItem>
  );
};

export default JournalForm;
