// apiRegistry.js

// api_endpoint_registry is a dictionary comprised of
// a set of the endpoints for eac feature
// AND
// a key for each endpoint that points to the feature it belongs to
export const api_endpoint_registry = {};

export const registerApiEndpoints = (endpoints, featureName) => {
  // Store the endpoints object directly under the feature name
  api_endpoint_registry[featureName] = endpoints;
  // Also add each endpoint with its feature name for direct lookup
  Object.keys(endpoints).forEach((endpoint) => {
    api_endpoint_registry[endpoint] = featureName;
  });

  // Return the endpoints for chaining
  return endpoints;
};
