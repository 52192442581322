// src/middleware/globalResetMiddleware.js
import { resetReduxCycle } from 'features/cycles/cyclesSlice';
import { resetNoAuthDoses } from 'features/doses/dosesSlice';
import { resetNoAuthAncillaries } from 'features/ancillaries/ancillariesSlice';
import { resetNoAuthPeptides } from 'features/peptides/peptidesSlice';
import { resetNoAuthBloods } from 'features/bloods/bloodsSlice';
import {
  resetActionsCounter,
  setGlobalRefresh,
} from 'features/global/globalSlice';
import { batch } from 'react-redux';

export const globalResetMiddleware = (store) => (next) => (action) => {
  // Pass the action to the next middleware first
  const result = next(action);

  // Then check if this is a global refresh action
  if (action.type === 'global/refreshGlobalRedux') {
    const payload = action.payload || {};

    // Case 1: Empty payload - Reset all slices' initialization flags
    if (Object.keys(payload).length === 0) {
      batch(() => {
        store.dispatch(resetReduxCycle());
        store.dispatch(resetNoAuthDoses());
        store.dispatch(resetNoAuthAncillaries());
        store.dispatch(resetNoAuthPeptides());
        store.dispatch(resetNoAuthBloods());
      });
      return result;
    }

    const { cycle, doses, ancillaries, peptides, bloods } = payload;

    // Get current state
    const state = store.getState();

    // Case 2: Normal update - Check each slice's initialized state and update with data
    const cycle_initialized = state.cycles.cycle_initialized;
    const doses_initialized = state.drugs.doses_initialized;
    const ancillaries_initialized = state.ancillaries.ancillaries_initialized;
    const peptides_initialized = state.peptides.peptides_initialized;
    const bloods_initialized = state.bloods.bloods_initialized;

    const updates = [];

    // Batch updates for slices that need initializing and have data
    batch(() => {
      if (!cycle_initialized) {
        store.dispatch(resetReduxCycle(cycle));
        updates.push(true);
      }

      if (cycle) {
        if (!doses_initialized) {
          store.dispatch(resetNoAuthDoses(doses));
          updates.push(true);
        }

        if (!ancillaries_initialized) {
          store.dispatch(resetNoAuthAncillaries(ancillaries));
          updates.push(true);
        }

        if (!peptides_initialized) {
          store.dispatch(resetNoAuthPeptides(peptides));
          updates.push(true);
        }

        if (!bloods_initialized) {
          store.dispatch(resetNoAuthBloods(bloods));
          updates.push(true);
        }

        // Only reset the actions counter if we updated any slices
        if (updates.length > 0) {
          store.dispatch(resetActionsCounter());
          // store.dispatch(setGlobalRefresh(true));
        }
      }
    });
  }

  return result;
};
