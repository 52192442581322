import { api } from 'app/api';
import { supabase } from 'lib/api';
import { registerApiEndpoints } from 'helpers/apiRegistry';
import { refreshGlobalRedux } from 'features/global/globalSlice';

export const cyclesAPI = api.injectEndpoints({
  feature_name: 'cycles',
  endpoints: (build) => ({
    getCycles: build.query({
      queryFn: async (user_data) => {
        const { data, error } = await supabase
          .from('Cycles')
          .select('*')
          .eq('user_id', user_data?.id)
          .order('updated_at', { ascending: false });
        return error ? { error } : { data };
      },
      providesTags: ['Cycle'],
    }),
    createCycle: build.mutation({
      queryFn: async (cycle_data) => {
        const { data, error } = await supabase
          .from('Cycles')
          .insert([cycle_data])
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: ['Cycle'],
    }),
    saveCycle: build.mutation({
      queryFn: async ({
        cycle_data,
        doses_data = [],
        ancillaries_data = [],
        peptides_data = [],
        bloods_data = [],
      }) => {
        const filtered_bloods_data = bloods_data.map((item) =>
          Object.fromEntries(
            Object.entries(item).filter(([_, value]) => value !== '')
          )
        );
        const { data, error } = await supabase.rpc('save_cycle_and_items', {
          cycle_data,
          doses_data,
          ancillaries_data,
          peptides_data,
          bloods_data: filtered_bloods_data,
        });
        return error ? { error } : { data };
      },
      invalidatesTags: ['Cycle'],
    }),
    getCycleById: build.query({
      queryFn: async (cycle_id) => {
        // Check for invalid cycle_id values
        if (!cycle_id) {
          return {
            error: {
              status: 400,
              data: 'Invalid cycle ID',
            },
          };
        }

        const { data, error } = await supabase
          .from('Cycles')
          .select(`*`)
          .eq('id', cycle_id)
          .limit(1)
          .single();
        return error ? { error } : { data };
      },
      providesTags: (result) => [{ type: 'Cycle', id: result?.id }],
    }),
    updateCycle: build.mutation({
      queryFn: async (cycle_data) => {
        const { data, error } = await supabase
          .from('Cycles')
          .update(cycle_data)
          .eq('id', cycle_data.id)
          .select();
        return error ? { error } : { data };
      },
      invalidatesTags: (result) => [{ type: 'Cycle', id: result.id }, 'Cycle'],
    }),
    deleteCycle: build.mutation({
      queryFn: async (cycle_id) => {
        // Check for invalid cycle_id values
        if (!cycle_id) {
          return {
            error: {
              status: 400,
              data: 'Invalid cycle ID',
            },
          };
        }

        const { data, error } = await supabase
          .from('Cycles')
          .delete()
          .eq('id', cycle_id);
        return error ? { error } : { data: { id: cycle_id } };
      },
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        // NOTE: This forces a redux refresh after the cycle is deleted
        try {
          await queryFulfilled;
          dispatch(refreshGlobalRedux());
        } catch (err) {}
      },
      invalidatesTags: ['Cycle'],
    }),
  }),
});

const cycles_endpoints = new Set([
  'getCycles',
  'createCycle',
  'saveCycle',
  'getCycleById',
  'updateCycle',
  'deleteCycle',
]);

registerApiEndpoints(cycles_endpoints, 'cycles');

export const {
  useGetCyclesQuery,
  useCreateCycleMutation,
  useSaveCycleMutation,
  useGetCycleByIdQuery,
  useUpdateCycleMutation,
  useDeleteCycleMutation,
} = cyclesAPI;
