import { api, RTK_TAGS } from 'app/api';
import { supabase } from 'lib/api';
import { registerApiEndpoints } from 'helpers/apiRegistry';

function serializeAuthApiError(error) {
  return {
    name: error.name,
    message: error.message,
    stack: error.stack,
    status: error.status,
  };
}

export const authAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query({
      queryFn: async () => {
        const { data, error } = await supabase.auth.getUser();
        if (error) throw serializeAuthApiError(error);
        return { data: data.user };
      },
      providesTags: ['User'],
    }),
    login: build.mutation({
      queryFn: async ({ ...login_data }) => {
        const { data, error } = await supabase.auth.signInWithPassword(
          login_data
        );
        if (error) throw serializeAuthApiError(error);
        return { data, error };
      },
      invalidatesTags: RTK_TAGS,
    }),
    signUp: build.mutation({
      queryFn: async ({ ...sign_up_data }) => {
        const { data, error } = await supabase.auth.signUp(sign_up_data);
        if (error) throw serializeAuthApiError(error);
        return { data, error };
      },
      invalidatesTags: RTK_TAGS,
    }),
    logout: build.mutation({
      queryFn: async () => {
        const { error } = await supabase.auth.signOut();
        if (error) throw serializeAuthApiError(error);
        return { error };
      },
    }),
  }),
});

const auth_endpoints = new Set(['getUser', 'login', 'signUp', 'logout']);

registerApiEndpoints(auth_endpoints, 'auth');

export const {
  useGetUserQuery,
  useLoginMutation,
  useSignUpMutation,
  useLogoutMutation,
} = authAPI;
