import { createSlice } from '@reduxjs/toolkit';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';
import { ancillariesAPI } from './ancillariesAPI';
import { NO_AUTH_ANCILLARIES } from 'constants';

const initialState = {
  status: 'idle',
  message: '',
  ancillaries_initialized: false,
  no_auth_ancillaries_mutated: false,
  no_auth_ancillaries: NO_AUTH_ANCILLARIES,
};

export const ancillariesSlice = createSlice({
  name: 'ancillaries',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetNoAuthAncillaries: (state, action) => {
      state.ancillaries_initialized = !!action?.payload;
      state.no_auth_ancillaries = action?.payload || NO_AUTH_ANCILLARIES;
      state.no_auth_ancillaries_mutated = false;
    },
    createAncillary: (state, action) => {
      state.no_auth_ancillaries.push(action.payload);
      state.no_auth_ancillaries_mutated = true;
    },
    updateAncillary: (state, action) => {
      const index = state.no_auth_ancillaries.findIndex(
        (dose) => dose.id === action.payload.id
      );
      if (index === -1) {
        throw new Error(`Ancillary id not found`);
      }
      state.no_auth_ancillaries[index] = action.payload;
      state.no_auth_ancillaries_mutated = true;
    },
    deleteAncillary: (state, action) => {
      state.no_auth_ancillaries = state.no_auth_ancillaries.filter(
        (dose) => dose.id !== action.payload
      );
      state.no_auth_ancillaries_mutated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      //getAncillariesByCycleId
      .addMatcher(
        ancillariesAPI.endpoints.getAncillariesByCycleId.matchFulfilled,
        (state, { payload }) => {
          state.no_auth_ancillaries = payload;
          state.ancillaries_initialized = true;
        }
      )
      .addMatcher(
        ancillariesAPI.endpoints.createAncillary.matchFulfilled,
        () => {
          customToast('success', 'Ancillary created successfully');
          trackEvent({
            event: 'ancillary_created',
            category: 'CRUD',
            table: 'Ancillaries',
            action: 'CREATE',
            auth: true,
          });
        }
      )
      .addMatcher(
        ancillariesAPI.endpoints.updateAncillary.matchFulfilled,
        () => {
          customToast('success', 'Ancillary updated successfully');
          trackEvent({
            event: 'ancillary_updated',
            category: 'CRUD',
            table: 'Ancillaries',
            action: 'UPDATE',
            auth: true,
          });
        }
      )
      .addMatcher(
        ancillariesAPI.endpoints.deleteAncillary.matchFulfilled,
        () => {
          customToast('success', 'Ancillary deleted successfully');
          trackEvent({
            event: 'ancillary_deleted',
            category: 'CRUD',
            table: 'Ancillaries',
            action: 'DELETE',
            auth: true,
          });
        }
      );
  },
});

export const {
  reset,
  resetNoAuthAncillaries,
  createAncillary,
  updateAncillary,
  deleteAncillary,
} = ancillariesSlice.actions;
export default ancillariesSlice.reducer;
