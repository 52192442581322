// src/components/Routes/RootRedirect.jsx
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useUser } from 'features/auth/hooks';
import { useGetCycles } from 'features/cycles/hooks';

const RootRedirect = () => {
  const {
    data: user,
    isLoading: user_loading,
    isFetching: user_fetching,
  } = useUser();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);

  const {
    data: cycles,
    isLoading: cycles_loading,
    isFetching: cycles_fetching,
    isError: cycles_error,
  } = useGetCycles();

  if (user_loading || user_fetching || cycles_loading || cycles_fetching) {
    return <></>;
  }

  if (!is_authenticated) {
    return <Navigate to='/cycles/no_auth_cycle/compounds' replace />;
  } else if (is_authenticated && cycles?.length > 0) {
    return <Navigate to={`/cycles/${cycles[0].id}/compounds`} replace />;
  }

  return <Navigate to={'/cycles/new'} replace />;
};

export default RootRedirect;
