import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { cyclesAPI } from './cyclesAPI';
import { authAPI } from '../auth/authAPI';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';
import { v4 as uuid_v4 } from 'uuid';
import moment from 'moment';

const initialState = {
  cycle_exists: false,
  cycle_initialized: false,
  no_auth_cycle_mutated: false,
  no_auth_cycle: {
    id: uuid_v4(),
    title: 'Login to Save Your Cycle',
    start_date: moment().format('YYYY-MM-DD HH:mm:ssZ'),
    weeks: 16,
    notes:
      'Sign Up or Login to Save Your Cycle. After logging in, you can add Cycle Notes, Ancillaries, Peptides+, Blood Work, and Journal Entries.',
    is_public: false,
  },
};

const cyclesSlice = createSlice({
  name: 'cycles',
  initialState,
  reducers: {
    resetReduxCycle: (state, action) => {
      // state.cycle_exists = false;
      state.cycle_initialized = !!action?.payload;
      state.no_auth_cycle = action?.payload || initialState.no_auth_cycle;
      state.no_auth_cycle_mutated = false;
    },
    updateCycle: (state, action) => {
      state.no_auth_cycle = action.payload;
      state.no_auth_cycle_mutated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authAPI.endpoints.logout.matchFulfilled, () => {
        customToast('success', 'Logged out successfully');
        return initialState;
      })
      .addMatcher(
        cyclesAPI.endpoints.getCycleById.matchFulfilled,
        (state, { payload }) => {
          state.cycle_exists = true;
          state.cycle_initialized = true;
          state.no_auth_cycle = payload;
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.getCycleById.matchRejected,
        (state, action) => {
          // This is where we can determine if the error was unauthorized
          // and if so, we can push to the dataLayer to track the event
          const { meta, error } = action;

          if (meta?.condition) {
            return;
          }
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.createCycle.matchFulfilled,
        (state, { payload }) => {
          customToast('success', 'Cycle created successfully');
          trackEvent({
            event: 'cycle_created',
            category: 'CRUD',
            action: 'CREATE',
            table: 'Cycles',
            title: payload[0].title,
            weeks: payload[0].weeks,
            start_date: payload[0].start_date,
            auth: true,
          });
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.createCycle.matchRejected,
        (state, action) => {
          const { meta, error } = action;

          if (meta?.condition) {
            return;
          }

          customToast(
            'error',
            'There was an error creating your cycle. Try again.'
          );
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.saveCycle.matchFulfilled,
        (state, { meta, error, payload }) => {
          customToast('success', 'Cycle saved successfully');
          trackEvent({
            event: 'cycle_saved',
            category: 'CRUD',
            action: 'SAVE',
            table: 'Cycles',
            auth: true,
          });
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.saveCycle.matchRejected,
        (state, { meta, error }) => {
          if (meta?.condition) {
            return;
          }

          customToast(
            'error',
            `There was an error saving your cycle. ${error.message}`
          );
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.updateCycle.matchFulfilled,
        (state, action) => {
          const { payload } = action;
          customToast('success', 'Cycle updated successfully');
          trackEvent({
            event: 'cycle_updated',
            category: 'CRUD',
            action: 'UPDATE',
            table: 'Cycles',
            title: payload[0].title,
            weeks: payload[0].weeks,
            start_date: payload[0].start_date,
            auth: true,
          });
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.updateCycle.matchRejected,
        (state, action) => {
          const { meta, error } = action;

          if (meta?.condition) {
            return;
          }

          customToast(
            'error',
            'There was an error updating your cycle. Try again.'
          );
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.deleteCycle.matchFulfilled,
        (state, { payload }) => {
          customToast('success', 'Cycle deleted successfully');
          trackEvent({
            event: 'cycle_deleted',
            category: 'CRUD',
            action: 'DELETE',
            table: 'Cycles',
            auth: true,
          });
        }
      )
      .addMatcher(
        cyclesAPI.endpoints.deleteCycle.matchRejected,
        (state, action) => {
          const { meta, error } = action;

          if (meta?.condition) {
            return;
          }

          customToast(
            'error',
            'There was an error deleting your cycle. Try again.'
          );
        }
      );
  },
});

export const selectCycleInfo = createSelector(
  (state) => state.cycles.cycle_exists,
  (state) => state.cycles.no_auth_cycle_mutated,
  (state) => state.cycles.cycle_initialized,
  (cycle_exists, no_auth_cycle_mutated, cycle_initialized) => {
    return {
      cycle_exists,
      no_auth_cycle_mutated,
      cycle_initialized,
    };
  }
);

export const { resetReduxCycle, updateCycle } = cyclesSlice.actions;

export default cyclesSlice.reducer;
