// src/middleware/actionCounterListener.js
import { createListenerMiddleware } from '@reduxjs/toolkit';
import { incrementActionsCounter } from 'features/global/globalSlice';

export const actionCounterListenerMiddleware = createListenerMiddleware();

actionCounterListenerMiddleware.startListening({
  predicate: (action) => {
    const type = action.type;

    // Exclude RTK Query (API) actions
    if (type.startsWith('api/')) {
      return false;
    }

    // Extract the reducer part after the slash
    const reducer_name = type.split('/')[1];

    // Match CRUD-related local actions
    const is_crud_action =
      reducer_name?.startsWith('create') ||
      reducer_name?.startsWith('update') ||
      reducer_name?.startsWith('delete');

    return is_crud_action;
  },
  effect: (action, listenerApi) => {
    // If the action is a local state CRUD-related action,
    // then increment the global actions_counter via dispatch
    listenerApi.dispatch(incrementActionsCounter());
  },
});
