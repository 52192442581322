/**
 * Helper function for handling analytics tracking with environment awareness
 * @param {Object} data - The analytics data to be pushed
 * @returns {void}
 */
export const trackEvent = (data) => {
  // Check if dataLayer exists
  if (typeof window.dataLayer === 'undefined') {
    if (process.env.NODE_ENV === 'development') {
      console.log('Analytics tracking (development only):', data);
    }
    return;
  }

  // Only track in production, or log in development
  if (process.env.NODE_ENV === 'production') {
    window.dataLayer.push({
      ...data,
      env: process.env.NODE_ENV,
    });
  } else if (process.env.NODE_ENV === 'development') {
    console.log('Analytics tracking (development only):', data);
  }
};
