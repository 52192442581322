// src/components/Shared/Footer.jsx
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Typography, useTheme } from '@mui/material';

const Footer = () => {
  const theme = useTheme();

  // Common link style with hover effect - using lighter colors
  const linkStyle = {
    mx: 1,
    transition: 'color 0.2s ease',
    color: theme.palette.text.disabled, // Much lighter than text.secondary
    '&:hover': {
      color: theme.palette.text.secondary, // Lighter than text.primary
      textDecoration: 'none', // Ensure no underline on hover
    },
  };

  return (
    <Box
      component='footer'
      sx={{
        py: 1,
        px: 2,
        mt: 'auto', // Push to bottom of flex container
        // borderTop: '1px solid',
        // borderColor: 'divider',
        width: '100vw',
        position: 'relative',
        left: '50%',
        right: '50%',
        marginLeft: '-50vw',
        marginRight: '-50vw',
        display: 'flex',
        justifyContent: 'center',
        boxSizing: 'border-box',
      }}>
      <Typography
        variant='body2'
        color={theme.palette.text.disabled} // Made lighter to match links
        align='center'
        sx={{ fontSize: '0.8rem' }}>
        <Link
          component={RouterLink}
          to='/privacy-policy'
          underline='none'
          sx={linkStyle}>
          Privacy Policy
        </Link>
        •
        <Link
          component={RouterLink}
          to='/about'
          underline='none'
          sx={linkStyle}>
          About
        </Link>
        •
        <Link
          href='mailto:admin@just1cycle.com'
          underline='none'
          sx={linkStyle}>
          Contact
        </Link>
        •
        <Typography component='span' sx={{ mx: 1, fontSize: 'inherit' }}>
          © {new Date().getFullYear()} Just1Cycle
        </Typography>
      </Typography>
    </Box>
  );
};

export default Footer;
