// React
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
// MUI
import { Box, Grid, Button, useTheme } from '@mui/material';
// Components
import {} from 'components';
// Features
import { selectDashboardInfo } from 'features/dashboard/dashboardSlice';
// Helpers
import { slugify } from 'helpers/slugify';

const CycleMenu = ({ is_small_screen }) => {
  const navigate = useNavigate();
  const { cycle_id, menu_selected } = useParams();
  const { menu_items } = useSelector(selectDashboardInfo);
  const theme = useTheme();

  const handleMenuClick = (name) => {
    if (cycle_id) {
      navigate(`/cycles/${cycle_id}/${slugify(name)}`);
    }
  };

  return (
    <Box
      sx={{
        maxWidth: '100vw',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}>
      <Grid
        container
        justifyContent='center'
        wrap={is_small_screen ? 'wrap' : 'nowrap'}>
        {menu_items?.map((name, i) => (
          <Grid
            item
            xs={6}
            p={0.5}
            sm='auto'
            key={name + '_grid_' + i}
            sx={{ maxWidth: '100vw' }}>
            <Button
              key={name + '_' + i}
              name={name}
              variant={
                slugify(name) === menu_selected ? 'contained' : 'outlined'
              }
              onClick={() => handleMenuClick(name)}
              sx={{ width: '100%' }}>
              {name}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CycleMenu;
