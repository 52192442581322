import { createSlice } from '@reduxjs/toolkit';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';
import { peptidesAPI } from './peptidesAPI';
import { NO_AUTH_PEPTIDES } from 'constants';

const initialState = {
  status: 'idle',
  message: '',
  peptides_initialized: false,
  no_auth_peptides_mutated: false,
  no_auth_peptides: NO_AUTH_PEPTIDES,
};

export const peptidesSlice = createSlice({
  name: 'peptides',
  initialState,
  reducers: {
    reset: (state) => initialState,
    resetNoAuthPeptides: (state, action) => {
      state.peptides_initialized = !!action?.payload;
      state.no_auth_peptides = action?.payload || NO_AUTH_PEPTIDES;
      state.no_auth_peptides_mutated = false;
    },
    createPeptide: (state, action) => {
      state.no_auth_peptides.push(action.payload);
      state.no_auth_peptides_mutated = true;
    },
    updatePeptide: (state, action) => {
      const index = state.no_auth_peptides.findIndex(
        (dose) => dose.id === action.payload.id
      );
      state.no_auth_peptides[index] = action.payload;
      state.no_auth_peptides_mutated = true;
    },
    deletePeptide: (state, action) => {
      state.no_auth_peptides = state.no_auth_peptides.filter(
        (dose) => dose.id !== action.payload
      );
      state.no_auth_peptides_mutated = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        peptidesAPI.endpoints.getPeptidesByCycleId.matchFulfilled,
        (state, { payload }) => {
          state.no_auth_peptides = payload;
          state.peptides_initialized = true;
        }
      )
      .addMatcher(peptidesAPI.endpoints.createPeptide.matchFulfilled, () => {
        customToast('success', 'Peptide created successfully');
        trackEvent({
          event: 'peptide_created',
          category: 'CRUD',
          table: 'Peptides',
          action: 'CREATE',
          auth: true,
        });
      })
      .addMatcher(peptidesAPI.endpoints.updatePeptide.matchFulfilled, () => {
        customToast('success', 'Peptide updated successfully');
        trackEvent({
          event: 'peptide_updated',
          category: 'CRUD',
          table: 'Peptides',
          action: 'UPDATE',
          auth: true,
        });
      })
      .addMatcher(peptidesAPI.endpoints.deletePeptide.matchFulfilled, () => {
        customToast('success', 'Peptide deleted successfully');
        trackEvent({
          event: 'peptide_deleted',
          category: 'CRUD',
          table: 'Peptides',
          action: 'DELETE',
          auth: true,
        });
      });
  },
});

export const {
  reset,
  resetNoAuthPeptides,
  createPeptide,
  updatePeptide,
  deletePeptide,
} = peptidesSlice.actions;
export default peptidesSlice.reducer;
