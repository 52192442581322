// React
import { useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// MUI
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Typography,
  MenuItem,
  Menu,
  Stack,
  Tooltip,
  Divider,
  Avatar,
  Chip,
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Menu as MenuIcon, Add, ExpandMore } from '@mui/icons-material';
import { dark_theme } from '../lib/theme';
import { ThemeProvider } from '@mui/material/styles';
// RTK Query
import { useLogoutMutation } from 'features/auth/authAPI';
// Hooks
import { useAuth } from 'hooks/useAuth';
import { useUser } from 'features/auth/hooks';
import { useRouteParams } from 'hooks/useRouteParams';
import { useGetCycles } from 'features/cycles/hooks';

function Header() {
  // Hooks
  const navigate = useNavigate();
  const location = useLocation();
  const { setQueryParam } = useRouteParams();
  const { openSignUp, openLogin } = useAuth();
  const { data: user, isLoading: user_loading } = useUser();
  const is_authenticated = useSelector((state) => state.auth.is_authenticated);
  const {
    data: cycles,
    isLoading: cycles_loading,
    isFetching: cycles_fetching,
  } = useGetCycles();
  const [logout] = useLogoutMutation();

  const public_cycles = cycles?.filter((cycle) => cycle.is_public);
  const private_cycles = cycles?.filter((cycle) => !cycle.is_public);

  // Component State
  const is_small_screen = useMediaQuery((theme) =>
    theme.breakpoints.down('sm')
  );
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElNavProfile, setAnchorElNavProfile] = useState(null);
  const appBarRef = useRef(null);

  const onLogout = async () => {
    logout();
  };

  const changeActiveCycle = (cycle_id) => {
    setAnchorElNav(null);
    navigate(`/cycles/${cycle_id}/compounds`);
  };

  const handleCycleCreate = () => {
    setAnchorElNav(null);
    if (user) {
      navigate('/cycles/new');
    } else if (
      !is_authenticated &&
      !location.pathname.includes('no_auth_cycle')
    ) {
      navigate('/');
    } else {
      openSignUp('create_cycle');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, paddingBottom: 0 }}>
      <AppBar position='static' ref={appBarRef}>
        <Toolbar
          sx={{ justifyContent: 'space-between', pl: '20px', pr: '18px' }}
          variant='dense'
          disableGutters>
          <Stack direction='row' alignItems={'center'}>
            <IconButton
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={(e) => setAnchorElNav(e.currentTarget)}
              sx={{ mr: 0 }}>
              <Tooltip title='Cycles' placement='bottom-start' arrow>
                <MenuIcon fontSize='inherit' />
              </Tooltip>
            </IconButton>

            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              keepMounted
              open={Boolean(anchorElNav)}
              onClose={() => setAnchorElNav(null)}
              MenuListProps={{
                style: {
                  padding: 0,
                },
              }}>
              {/* Menu Header */}
              <Typography variant='h6' align={'center'} p={0.5}>
                Cycles
              </Typography>
              {cycles && <Divider />}

              {/* Cycles List with Private/Public Sections */}
              {!cycles_loading && cycles && (
                <Box pb={1} pt={public_cycles.length ? 1 : 0}>
                  {/* Private Cycles Section */}
                  {!!private_cycles.length && (
                    <>
                      {!!public_cycles.length && (
                        <Typography variant='overline' px={1} py={0}>
                          Private
                        </Typography>
                      )}
                      {private_cycles.map((cycle, index) => (
                        <MenuItem
                          disableGutters
                          key={cycle.id}
                          onClick={() => {
                            changeActiveCycle(cycle.id);
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              px: 1, // Increased padding to align with the header
                            }}>
                            <Chip
                              size='small'
                              label={index + 1}
                              color='primary'
                            />
                            <Typography variant='inherit' ml={1} noWrap>
                              {cycle.title}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                      {!!public_cycles.length && <Divider sx={{ mb: 0 }} />}
                    </>
                  )}

                  {/* Public Cycles Section */}
                  {!!public_cycles.length && (
                    <>
                      <Typography variant='overline' px={1}>
                        Public
                      </Typography>
                      {public_cycles.map((cycle, index) => (
                        <MenuItem
                          disableGutters
                          key={cycle.id}
                          onClick={() => {
                            changeActiveCycle(cycle.id);
                          }}>
                          <Box
                            sx={{
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                              px: 1,
                            }}>
                            <Chip
                              size='small'
                              label={index + 1}
                              sx={{
                                backgroundColor: cycle.is_public
                                  ? 'secondary.main'
                                  : 'grey.200',
                                color: cycle.is_public
                                  ? 'white'
                                  : 'text.primary',
                              }}
                            />
                            <Typography variant='inherit' ml={1} noWrap>
                              {cycle.title}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </>
                  )}
                </Box>
              )}

              {/* Create Cycle Button */}
              <Box
                p={1}
                pt={0}
                sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant='contained'
                  onClick={handleCycleCreate}
                  endIcon={<Add />}>
                  Create Cycle
                </Button>
              </Box>
            </Menu>

            <Box
              component={'img'}
              sx={{
                display: 'flex',
                height: '21px',
                alignSelf: 'center',
                justifySelf: 'center',
                cursor: is_authenticated ? '' : 'pointer',
              }}
              src='/just1cycle-white-text.svg'
              alt='title-logo'
              onClick={() => {
                if (!is_authenticated) {
                  handleCycleCreate();
                }
              }}
            />
          </Stack>

          {!user_loading && user ? (
            <>
              <Tooltip title='Account' placement='bottom-end' arrow>
                <Button
                  variant=''
                  sx={{
                    alignItems: 'center',
                    textTransform: 'lowercase',
                    paddingRight: 0,
                  }}
                  onClick={(e) => setAnchorElNavProfile(e.currentTarget)}>
                  <ExpandMore />
                  {!is_small_screen && (
                    <Typography variant='subtitle1' fontWeight='light'>
                      {user.email.split('@')[0]}
                    </Typography>
                  )}

                  <Avatar
                    alt={user?.email[0].toUpperCase()}
                    src={user?.identities[0]?.identity_data?.avatar_url}
                    sx={{ ml: 1, height: 25, width: 25 }}
                  />
                </Button>
              </Tooltip>

              <Menu
                id='menu-appbar'
                anchorEl={anchorElNavProfile}
                keepMounted
                open={Boolean(anchorElNavProfile)}
                onClose={() => setAnchorElNavProfile(null)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}>
                {/* <MenuItem onClick={() => console.log('go to profile')}>
                    Profile
                  </MenuItem> */}
                <MenuItem
                  onClick={() => {
                    setQueryParam('privacy_policy', 'true');
                    setAnchorElNavProfile(null);
                  }}>
                  Privacy Policy
                </MenuItem>
                <MenuItem onClick={onLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <ThemeProvider theme={dark_theme}>
              <Stack direction={'row'} alignItems='center'>
                <Button variant='outlined' onClick={() => openLogin('header')}>
                  Login
                </Button>
                <Button
                  variant='contained'
                  onClick={() => openSignUp('header')}
                  sx={{ ml: 1 }}>
                  Sign Up
                </Button>
              </Stack>
            </ThemeProvider>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
