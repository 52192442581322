// src/helpers/slugify.js
/**
 * Converts a string to a URL-friendly slug
 * @param {string} text - The text to slugify
 * @returns {string} - The slugified text
 */
export function slugify(text) {
  if (!text) return '';

  return text
    .toString()
    .toLowerCase()
    .replace(/\+/g, '') // Remove plus signs
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    .replace(/\-\-+/g, '-') // Replace multiple hyphens with single hyphen
    .replace(/^-+/, '') // Trim hyphens from start
    .replace(/-+$/, ''); // Trim hyphens from end
}

/**
 * Converts a slug back to a menu item name
 * @param {string} slug - The slug to deslugify
 * @returns {string} - The original menu item name (approximate)
 */
export function deslugify(slug) {
  if (!slug) return '';

  // Map of known slugs to their original forms
  const slugMap = {
    compounds: 'COMPOUNDS',
    ancillaries: 'ANCILLARIES',
    peptides: 'PEPTIDES+',
    'blood-work': 'BLOOD WORK',
    journal: 'JOURNAL',
  };

  return slugMap[slug] || '';
}
