// src/store.js
import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from 'features/dashboard/dashboardSlice';
import drugReducer from 'features/doses/dosesSlice';
import cycleReducer from 'features/cycles/cyclesSlice';
import authReducer from 'features/auth/authSlice';
import bloodsReducer from 'features/bloods/bloodsSlice';
import ancillariesReducer from 'features/ancillaries/ancillariesSlice';
import peptidesReducer from 'features/peptides/peptidesSlice';
import globalReducer from 'features/global/globalSlice';
import { api } from './api';
import { actionCounterListenerMiddleware } from 'middleware/actionCounterListener';
import { apiListenerMiddleware } from 'middleware/apiListener';
import { globalResetMiddleware } from 'middleware/globalResetMiddleware';

// Debug: Logger middleware to log non-api actions with essential info
const debugMiddleware = (store) => (next) => (action) => {
  // Check if action type is a string and doesn't start with 'api/'
  if (
    typeof action.type === 'string' &&
    !action.type.startsWith('api/') &&
    action.type.startsWith('global/')
  ) {
    const safePayload =
      action.payload && typeof action.payload === 'object'
        ? Object.fromEntries(
            Object.entries(action.payload).filter(
              ([_, value]) =>
                value !== undefined &&
                value !== null &&
                value !== '' &&
                typeof value === 'object'
            )
          )
        : action.payload;

    console.log('Action:', {
      type: action.type,
      state: store.getState(),
      payload: safePayload,
    });
  }
  return next(action);
};

export const store = configureStore({
  reducer: {
    global: globalReducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    cycles: cycleReducer,
    drugs: drugReducer,
    bloods: bloodsReducer,
    ancillaries: ancillariesReducer,
    peptides: peptidesReducer,
    api: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(api.middleware)
      .concat(actionCounterListenerMiddleware.middleware)
      // TODO: Enable this api listener if global api error needed
      // .concat(apiListenerMiddleware.middleware)
      .concat(globalResetMiddleware),
  // .concat(debugMiddleware),
});
