// src/features/global/globalSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

const initialState = {
  api_error: false,
  actions_counter: 0,
  triggeredNoAuthCycle: false,
  global_refresh: false,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    refreshGlobalRedux: (state, action) => {},
    setGlobalRefresh: (state, action) => {
      state.global_refresh = action.payload;
    },
    toggleNoAuthCycle: (state) => {
      state.actions_counter = 0;
      state.triggeredNoAuthCycle = true;
    },
    resetActionsCounter: (state) => {
      state.actions_counter = 0;
    },
    setGlobalAPIError: (state, action) => {
      state.api_error = action.payload;
    },
    incrementActionsCounter: (state) => {
      state.actions_counter += 1;
    },
  },
});

// Export selectors
export const selectGlobalInfo = createSelector(
  (state) => state.global.api_error,
  (state) => state.global.actions_counter,
  (state) => state.global.triggeredNoAuthCycle,
  (state) => state.global.global_refresh,
  (api_error, actions_counter, triggeredNoAuthCycle, global_refresh) => {
    return {
      api_error,
      actions_counter,
      triggeredNoAuthCycle,
      global_refresh,
    };
  }
);

export const {
  refreshGlobalRedux,
  setGlobalRefresh,
  toggleNoAuthCycle,
  resetActionsCounter,
  setGlobalAPIError,
  incrementActionsCounter,
} = globalSlice.actions;
export default globalSlice.reducer;
