import { authAPI } from './authAPI';
import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { customToast } from 'helpers/customToast';
import { trackEvent } from 'helpers/analytics';
import Cookies from 'js-cookie';

const initialState = {
  is_authenticated: false,
  auth_failed: false,
  user_id: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authAPI.endpoints.getUser.matchFulfilled, (state, action) => {
        state.is_authenticated = true;
        state.user_id = action.payload.id;
      })
      .addMatcher(authAPI.endpoints.getUser.matchRejected, (state, action) => {
        const { meta, error } = action;
        if (meta?.condition) {
          return;
        } else {
          state.auth_failed = true;
        }
      })
      .addMatcher(authAPI.endpoints.login.matchRejected, (state, action) => {
        if (action?.error?.message === 'Email not confirmed') {
          customToast(
            'info',
            'Check your email for verification.',
            10000
          );
          trackEvent({
            event: 'login_failed_needs_verification',
            category: 'Auth',
            action: 'LOGIN',
            auth: false,
          });
        } else {
          customToast(
            'error',
            action?.error?.message || 'Something went wrong. Try again.',
            5000
          );
        }
      })
      .addMatcher(authAPI.endpoints.login.matchFulfilled, (state, action) => {
        trackEvent({
          event: 'login_success',
          category: 'Auth',
          action: 'LOGIN',
          auth: true,
        });
        window.location.replace('/');
      })
      .addMatcher(authAPI.endpoints.signUp.matchRejected, (state, action) => {
        customToast('error', action?.error?.message);
      })
      .addMatcher(authAPI.endpoints.signUp.matchFulfilled, (state, action) => {
        // Handle sign up for user created more than 10 seconds ago and unverified
        if (
          action?.payload?.user &&
          Math.floor(
            (Date.now() - Date.parse(action.payload.user?.created_at)) / 1000
          ) > 10
        ) {
          let toast_message = 'Check your email for verification.';
          if (action?.payload?.user?.email_confirmed_at) {
            toast_message = 'You have successfully signed up! Proceed to login.';
          } else if (action?.payload?.user?.email) {
            toast_message = `User already exists. Check the following email for verification: ${action?.payload?.user?.email}`;
          }
          customToast('info', toast_message, 10000);
          trackEvent({
            event: 'sign_up_needs_verification',
            category: 'Auth',
            action: 'SIGN_UP',
            auth: false,
          });
        }
        // Handle inital user sign up, where user is less than 10 seconds old
        else {
          customToast(
            'success',
            action?.payload?.user?.email
              ? `Check the following email for verification: ${action?.payload?.user?.email}`
              : 'Check your email for verification.',
            10000
          );
          trackEvent({
            event: 'sign_up_success',
            category: 'Auth',
            action: 'SIGN_UP',
            auth: false,
          });
        }
      })
      .addMatcher(authAPI.endpoints.logout.matchFulfilled, (state) => {
        for (const i in localStorage) {
          if (i.includes('sb')) localStorage.removeItem(i);
        }
        Cookies.remove('sb-refresh-token');
        Cookies.remove('sb-access-token');
        state.is_authenticated = false;
        state.user_id = null;
        trackEvent({
          event: 'logout_success',
          category: 'Auth',
          action: 'LOGOUT',
          auth: true,
        });
        window.location.reload(false);
      })
      .addMatcher(authAPI.endpoints.logout.matchRejected, (state) => {
        customToast('error', 'Something went wrong. Try again.');
      });
  },
});

export const selectAuthInfo = createSelector(
  (state) => state.auth.is_authenticated,
  (is_authenticated) => {
    return {
      is_authenticated,
    };
  }
);

export const { reset } = authSlice.actions;

export default authSlice.reducer;
