// src/components/Legal/About.jsx
import React from 'react';
import { Typography, Link, Box, useTheme } from '@mui/material';

const About = () => {
  const theme = useTheme();

  return (
    <Box>
      <Typography variant='h4' component='h1' gutterBottom>
        About{' '}
        <Box
          component='span'
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            display: 'inline',
          }}>
          Just1Cycle
        </Box>
      </Typography>

      <Typography paragraph>
        <Box
          component='span'
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
          }}>
          Just1Cycle
        </Box>{' '}
        is a comprehensive platform designed to help individuals track and
        optimize their performance enhancement cycles.
      </Typography>

      <Typography paragraph>
        Our mission is to provide a secure, private, and user-friendly tool that
        enables users to make informed decisions about their health and fitness
        journey.
      </Typography>

      <Typography paragraph>
        <Box
          component='span'
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
          }}>
          Just1Cycle
        </Box>{' '}
        allows you to:
      </Typography>

      <ul>
        <li>
          <Typography>Track compounds, peptides, and ancillaries</Typography>
        </li>
        <li>
          <Typography>Monitor blood work results</Typography>
        </li>
        <li>
          <Typography>Keep a detailed journal of your progress</Typography>
        </li>
        <li>
          <Typography>
            Visualize your cycle data with comprehensive charts
          </Typography>
        </li>
        <li>
          <Typography>Share your cycles securely with others</Typography>
        </li>
      </ul>

      <Typography paragraph>
        Whether you're a beginner or experienced user,{' '}
        <Box
          component='span'
          sx={{
            color: theme.palette.primary.main,
            fontWeight: 'bold',
            display: 'inline',
          }}>
          Just1Cycle
        </Box>{' '}
        provides the tools you need to plan, track, and analyze your cycles
        effectively.
      </Typography>

      <Typography variant='h6' component='h2' gutterBottom sx={{ mt: 3 }}>
        Contact Us
      </Typography>

      <Typography paragraph>
        If you have any questions or feedback, please don't hesitate to reach
        out to us at{' '}
        <Link href='mailto:admin@just1cycle.com'>admin@just1cycle.com</Link>.
      </Typography>

      <Typography variant='body2' color='text.secondary' sx={{ mt: 4 }}>
        © {new Date().getFullYear()} Just1Cycle LLC
      </Typography>
    </Box>
  );
};

export default About;
