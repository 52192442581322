import React from 'react';
import { Glyph } from '@visx/glyph';
import TinyColor from 'tinycolor2';
import { lighterColorHash } from 'helpers/colorHash';

const DoseGlyph = ({ datum, data_key }) => {
  if (+parseFloat(datum[data_key]).toFixed(2) === 0) return null;
  const isTotal = !data_key.includes('_');
  const color = TinyColor(
    lighterColorHash(data_key.split('_')[0].split('-')[0])
  );

  return (
    <Glyph key={datum.days} left={datum.left} top={datum.top}>
      <circle
        r={isTotal ? 5 : 4}
        fill={isTotal ? 'white' : color}
        stroke={color}
        strokeWidth={isTotal ? 2 : 0}
      />
    </Glyph>
  );
};

export default DoseGlyph;
